
import { ApiGetHierarchicalMemberOrganizationDto } from "@/api/generated/Api";
import { defineComponent } from "@vue/composition-api";
import { PropType } from "vue";

export default defineComponent({
  name: "TheMemberOrganizaionModalTreeHierarchy",
  emits: ["update"],
  props: {
    buttonLabel: {
      type: String,
      default: "Velg arrangør",
      required: false,
    },
    memberOrgHierarchi: {
      type: Array as PropType<ApiGetHierarchicalMemberOrganizationDto[]> as [],
      required: true,
    },
  },
  setup(_, { emit }) {
    const update = (item: ApiGetHierarchicalMemberOrganizationDto) => {
      emit("update", item);
    };

    return {
      update,
    };
  },
});
