var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": "",
      "disabled": _vm.isCourseDone
    }
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('CourseInfo', {
    staticClass: "section-container",
    attrs: {
      "courseMode": _vm.courseMode
    },
    on: {
      "input": _vm.emitChanges
    },
    model: {
      value: _vm.courseValues,
      callback: function callback($$v) {
        _vm.courseValues = $$v;
      },
      expression: "courseValues"
    }
  }), _c('CourseLocationInfo', {
    staticClass: "section-container",
    on: {
      "input": _vm.emitChanges
    },
    model: {
      value: _vm.courseValues,
      callback: function callback($$v) {
        _vm.courseValues = $$v;
      },
      expression: "courseValues"
    }
  }), _c('CourseDates', {
    staticClass: "section-container",
    on: {
      "input": _vm.emitChanges
    },
    model: {
      value: _vm.courseValues,
      callback: function callback($$v) {
        _vm.courseValues = $$v;
      },
      expression: "courseValues"
    }
  }), _c('CourseTeachingHours', {
    staticClass: "section-container",
    attrs: {
      "teachingMethod": _vm.curriculum.teachingMethod
    },
    on: {
      "input": _vm.emitChanges
    },
    model: {
      value: _vm.courseValues,
      callback: function callback($$v) {
        _vm.courseValues = $$v;
      },
      expression: "courseValues"
    }
  }), _c('CourseCoordinators', {
    staticClass: "section-container",
    attrs: {
      "courseMode": _vm.courseMode
    },
    on: {
      "input": _vm.emitChanges
    },
    model: {
      value: _vm.courseValues,
      callback: function callback($$v) {
        _vm.courseValues = $$v;
      },
      expression: "courseValues"
    }
  }), _c('CourseRegistrationInfo', {
    staticClass: "section-container",
    attrs: {
      "curriculum": _vm.curriculum
    },
    on: {
      "input": _vm.emitChanges
    },
    model: {
      value: _vm.courseValues,
      callback: function callback($$v) {
        _vm.courseValues = $$v;
      },
      expression: "courseValues"
    }
  }), !_vm.isCourseDone ? _c('div', {
    staticClass: "title font-weight-light text-center",
    staticStyle: {
      "align-self": "center"
    }
  }, [_vm._v(" Du kan laste opp filer når " + _vm._s(_vm.isVocationalSchool ? "utdanningen" : "kurset") + " er opprettet ")]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }