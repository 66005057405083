
import { isVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent } from "@vue/composition-api";
export default defineComponent({
  name: "CourseSection",
  props: {
    title: {
      type: String,
      required: false,
    },
  },
  setup() {
    const store = useStore<StoreState>();

    return {
      isVocationalSchool: computed(() => isVocationalSchool(store.state.courses.curriculum.mainCourseId)),
      curriculum: computed(() => store.state.courses.curriculum),
      getCourse: computed(() => store.state.courses.course),
    };
  },
});
