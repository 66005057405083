var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-expansion-panels', {
    attrs: {
      "accordion": "",
      "value": _vm.activePanel
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v(_vm._s(_vm.panelLabels[0].label) + " *")]), _c('v-expansion-panel-content', [_c('tiptap-vuetify', {
    attrs: {
      "placeholder": _vm.panelLabels[0].label,
      "card-props": {
        outlined: true
      },
      "extensions": _vm.extensions,
      "data-cy": "intro",
      "disabled": _vm.isCourseDone
    },
    on: {
      "blur": _vm.emitChanges
    },
    model: {
      value: _vm.webDescription.intro,
      callback: function callback($$v) {
        _vm.$set(_vm.webDescription, "intro", $$v);
      },
      expression: "webDescription.intro"
    }
  })], 1)], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v(" " + _vm._s(_vm.panelLabels[1].label))]), _c('v-expansion-panel-content', [_c('tiptap-vuetify', {
    attrs: {
      "placeholder": _vm.panelLabels[1].label,
      "card-props": {
        outlined: true
      },
      "extensions": _vm.extensions,
      "disabled": _vm.isCourseDone
    },
    on: {
      "blur": _vm.emitChanges
    },
    model: {
      value: _vm.webDescription.targetGroup,
      callback: function callback($$v) {
        _vm.$set(_vm.webDescription, "targetGroup", $$v);
      },
      expression: "webDescription.targetGroup"
    }
  })], 1)], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v(_vm._s(_vm.panelLabels[2].label))]), _c('v-expansion-panel-content', [_c('tiptap-vuetify', {
    attrs: {
      "placeholder": _vm.panelLabels[2].label,
      "card-props": {
        outlined: true
      },
      "extensions": _vm.extensions,
      "disabled": _vm.isCourseDone
    },
    on: {
      "blur": _vm.emitChanges
    },
    model: {
      value: _vm.webDescription.foreknowledgeRequirements,
      callback: function callback($$v) {
        _vm.$set(_vm.webDescription, "foreknowledgeRequirements", $$v);
      },
      expression: "webDescription.foreknowledgeRequirements"
    }
  })], 1)], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v(" " + _vm._s(_vm.panelLabels[3].label))]), _c('v-expansion-panel-content', [_c('tiptap-vuetify', {
    attrs: {
      "placeholder": _vm.panelLabels[3].label,
      "card-props": {
        outlined: true
      },
      "extensions": _vm.extensions,
      "disabled": _vm.isCourseDone
    },
    on: {
      "blur": _vm.emitChanges
    },
    model: {
      value: _vm.webDescription.learningObjectives,
      callback: function callback($$v) {
        _vm.$set(_vm.webDescription, "learningObjectives", $$v);
      },
      expression: "webDescription.learningObjectives"
    }
  })], 1)], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v(" " + _vm._s(_vm.panelLabels[4].label))]), _c('v-expansion-panel-content', [_c('tiptap-vuetify', {
    attrs: {
      "placeholder": _vm.panelLabels[4].label,
      "card-props": {
        outlined: true
      },
      "extensions": _vm.extensions,
      "disabled": _vm.isCourseDone
    },
    on: {
      "blur": _vm.emitChanges
    },
    model: {
      value: _vm.webDescription.courseContent,
      callback: function callback($$v) {
        _vm.$set(_vm.webDescription, "courseContent", $$v);
      },
      expression: "webDescription.courseContent"
    }
  })], 1)], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v(_vm._s(_vm.panelLabels[5].label))]), _c('v-expansion-panel-content', [_c('tiptap-vuetify', {
    attrs: {
      "placeholder": _vm.panelLabels[5].label,
      "card-props": {
        outlined: true
      },
      "extensions": _vm.extensions,
      "disabled": _vm.isCourseDone
    },
    on: {
      "blur": _vm.emitChanges
    },
    model: {
      value: _vm.webDescription.method,
      callback: function callback($$v) {
        _vm.$set(_vm.webDescription, "method", $$v);
      },
      expression: "webDescription.method"
    }
  })], 1)], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v(_vm._s(_vm.panelLabels[6].label) + " *")]), _c('v-expansion-panel-content', [_c('tiptap-vuetify', {
    attrs: {
      "placeholder": _vm.panelLabels[6].label,
      "card-props": {
        outlined: true
      },
      "extensions": _vm.extensions,
      "data-cy": "financing",
      "disabled": _vm.isCourseDone
    },
    on: {
      "blur": _vm.emitChanges
    },
    model: {
      value: _vm.webDescription.financing,
      callback: function callback($$v) {
        _vm.$set(_vm.webDescription, "financing", $$v);
      },
      expression: "webDescription.financing"
    }
  })], 1)], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v(_vm._s(_vm.panelLabels[7].label) + " *")]), _c('v-expansion-panel-content', [_c('tiptap-vuetify', {
    attrs: {
      "placeholder": _vm.panelLabels[7].label,
      "card-props": {
        outlined: true
      },
      "extensions": _vm.extensions,
      "data-cy": "exam",
      "disabled": _vm.isCourseDone
    },
    on: {
      "blur": _vm.emitChanges
    },
    model: {
      value: _vm.webDescription.exam,
      callback: function callback($$v) {
        _vm.$set(_vm.webDescription, "exam", $$v);
      },
      expression: "webDescription.exam"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }