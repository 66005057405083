var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-autocomplete', _vm._b({
    attrs: {
      "value": _vm.value,
      "clearable": "",
      "label": "".concat(_vm.label, " ").concat(_vm.mandatory ? ' *' : ''),
      "placeholder": "Søk etter fornavn og/eller etternavn",
      "no-data-text": "Skriv minst ".concat(_vm.minLength, " bokstaver for \xE5 s\xF8ke"),
      "items": _vm.employees,
      "loading": _vm.isLoading,
      "item-text": _vm.itemText,
      "item-value": "id",
      "rules": _vm.mandatory ? _vm.validateNotEmpty : [],
      "filter": _vm.employeeFilter,
      "data-cy": _vm.cypressId
    },
    on: {
      "update:search-input": _vm.searchEmployee,
      "change": function change($event) {
        return _vm.$emit('input', $event);
      }
    }
  }, 'v-autocomplete', _vm.$attrs, false));
}
var staticRenderFns = []

export { render, staticRenderFns }