
import BaseTooltipIconButton from "@/components/shared/button/BaseTooltipIconButton.vue";
import { hasMemberOrgAccess } from "@/shared/helpers/accessLevelApiAdapter";
import { getIsoDate } from "@/shared/helpers/dateHelpers";
import { defineComponent, PropType, ref } from "@vue/composition-api";
import { CalendarTimestamp } from "vuetify";
import { VuetifyCalendarEvent } from "./schedule.types";

export default defineComponent({
  name: "ScheduleCalendar",
  components: { BaseTooltipIconButton },
  props: {
    events: {
      type: Array as PropType<VuetifyCalendarEvent[]>,
      required: true,
    },
    startDate: {
      type: Date,
      required: true,
    },
    endDate: {
      type: Date,
      required: true,
    },
    calendarHeight: {
      type: String,
      required: false,
      default: "600",
    },
  },
  emits: ["clickEvent", "clickDate"],
  setup(props, { emit }) {
    const calendarValue = ref<string>(getIsoDate(props.startDate));
    const getEventColor = (event: VuetifyCalendarEvent) => event.color;
    const weekdays = [1, 2, 3, 4, 5, 6, 0];

    const setToday = () => {
      calendarValue.value = getIsoDate(new Date());
    };

    const handleClickEvent = ({
      event: calendarEvent,
      nativeEvent,
    }: {
      event: VuetifyCalendarEvent;
      nativeEvent: MouseEvent;
    }) => {
      if (calendarEvent) {
        calendarValue.value = getIsoDate(calendarEvent.start);
      }
      emit("clickEvent", { calendarEvent, nativeEvent });
    };

    const handleClickDate = (event: CalendarTimestamp) => {
      emit("clickDate", event);
    };

    return {
      getEventColor,
      weekdays,
      calendarValue,
      setToday,
      handleClickEvent,
      handleClickDate,
      hasMemberOrgAccess,
    };
  },
});
