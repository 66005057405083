
import { postFile } from "@/api/file.api";
import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  name: "UploadCourseFileDialog",
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    //  Entity = curriculum, course or user
    entity: {
      type: [String, Number],
      required: true,
    },
    description: {
      type: String,
    },
    category: {
      type: String,
    },
    fileType: {
      type: String,
      default: "image/*",
    },
  },
  data() {
    return {
      files: [],
      loading: false,
      error: null,
    };
  },
  methods: {
    // TODO : Create fileInterface.ts so this can share interface with the existing FILE interface.
    async uploadFile(file: any) {
      const formData = new FormData();
      // formData.append("entities", JSON.stringify({ [this.entity]: this.id }));
      formData.append("Description", (this as any).description);
      if (this.category) {
        formData.append("Category", this.category);
      }
      formData.append("file", file);
      const res = await postFile((this as any).entity, (this as any).id, formData);
      return {
        id: res.data.id,
        originalFileName: file.name,
        mimeType: file.type,
      };
    },
    async uploadFiles() {
      const uploadedFiles = [];
      this.loading = true;
      try {
        for (const file of this.files) {
          uploadedFiles.push(await this.uploadFile(file));
        }
      } catch (err: any) {
        this.error = err?.response.data;
      } finally {
        this.loading = false;
      }
      return uploadedFiles;
    },
    async onOk() {
      const uploadedFiles = await this.uploadFiles();
      if (!this.error) {
        this.$emit("ok", uploadedFiles);
        this.hide();
      }
    },
    onCancel() {
      this.hide();
    },
    hide() {
      this.$emit("hide");
    },
  },
});
