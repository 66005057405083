
import { deleteFile, getCourseFilesById } from "@/api/file.api";
import { mapFields } from "vuex-map-fields";
import CourseSection from "@/components/course/CourseSection.vue";
import UploadCourseFileDialog from "@/components/course/new/files/UploadCourseFileDialog.vue";
import { defineComponent } from "@vue/composition-api";

interface File {
  id: string;
  originalFileName: string;
  description: string;
  category: any;
  mimeType: string;
}

interface ComponentData {
  files: File[];
}

export default defineComponent({
  name: "UploadCourseFile",
  components: { CourseSection },
  props: {
    id: { type: [String, Number], required: true },
  },
  data(): ComponentData {
    return {
      files: [],
    };
  },
  methods: {
    deleteFile(fileId: any) {
      this.files = this.files.filter((file) => file.id != fileId);
      deleteFile(fileId);
    },
    openUploadDialog() {
      (this as any)
        .$dialog({
          component: UploadCourseFileDialog,
          parent: this,
          id: this.id,
          category: "kontrakt",
          entity: "course",
        })
        .onOk((files: File[]) => (this.files = [...(this.files || []), ...files]));
    },
  },
  computed: {
    ...mapFields("execution", ["executionPlan.info.groupIdRequired"]),
  },
  async mounted() {
    // TODO : Can file and files object be typescripted better ?
    const res = await getCourseFilesById((this as any).id);
    for (const file of res.data) {
      if (file.category === "kontrakt") {
        this.files.push({
          id: file.id,
          originalFileName: file.originalFileName,
          description: file.description,
          category: file.category,
          mimeType: file.mimeType,
        });
      }
    }
  },
});
