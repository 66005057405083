<script>
import { mapActions, mapGetters } from "vuex";
import BaseTable from "@/components/shared/table/BaseTable.vue";
export default {
  name: "BaseTableFiltered",
  components: { BaseTable },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    filter: {
      type: Array,
      default: () => [],
    },
    titleClass: {
      type: String,
      default: "",
    },
    hasServerSidePagination: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 30,
    },
  },
  watch: {
    items() {
      this.generateFilter();
    },
    filter() {
      this.generateFilter();
    },
    filterModel: {
      immediate: true,
      deep: true,
      handler() {
        this.generateFilter();
      },
    },
  },
  methods: {
    ...mapActions("filter", { setFilter: "set" }),
    defaultFilterFunction(value, model) {
      if (!model) {
        return true;
      }
      // eslint-disable-next-line no-prototype-builtins
      if (model.hasOwnProperty("length")) {
        if (model.length > 0) {
          return model.toLowerCase() === value?.toLowerCase();
        }
        return true;
      }
      return value === model;
    },
    generateFilter() {
      const customFilters = this.filter.map((current) => current.value);
      const filteredColumns = Object.keys(this.filterModel).filter((f) => !customFilters.includes(f));

      const items = this.filteredItems.reduce((result, item) => {
        for (const [col, value] of Object.entries(item)) {
          const isFiltered = filteredColumns.some((filterCol) => {
            if (filterCol == col) {
              return false;
            }
            const value = item[filterCol];
            return !this.defaultFilterFunction(value, this.filterModel[filterCol]);
          });
          if (!result[col]) {
            result[col] = [];
          }
          if (isFiltered || result[col].includes(value)) {
            continue;
          }

          result[col].push(value);
        }
        return result;
      }, {});
      const filter = this.headers
        .filter((header) => header.filter)
        .map(({ value, text }) => ({
          value,
          attrs: {
            label: text,
            items: items[value],
            itemText: "description",
            itemValue: "description",
            dense: true,
            hideDetails: true,
            clearable: true,
          },
        }));
      this.setFilter([...filter, ...this.filter]);
    },
  },
  computed: {
    ...mapGetters("filter", {
      filterModel: "model",
    }),
    filteredItems() {
      if (this.hasServerSidePagination) {
        if (this.filter) {
          for (const filter of this.filter) {
            const model = this.filterModel[filter.value];

            filter.apply && filter.apply(null, model);
          }
        }
        return this.items;
      }

      return this.items.filter((item) => {
        for (const filter of this.filter) {
          const model = this.filterModel[filter.value];
          if (model === undefined) {
            continue;
          }
          // actuall sorting call. TODO better name than apply
          const isCustomFilterMatch = (filter.apply ?? this.defaultFilterFunction)?.(item[filter.value], model) ?? true;
          if (!isCustomFilterMatch) {
            return false;
          }
        }
        return true;
      });
    },
    innerHeaders() {
      return this.headers.map((currentHeader) => {
        const { filter, ...rest } = currentHeader;
        const header = {
          ...rest,
        };
        if (filter) {
          header.filter = (value) => this.defaultFilterFunction(value, this.filterModel[currentHeader.value]);
        }
        return header;
      });
    },
  },
};
</script>
<template>
  <BaseTable
    data-cy="baseTable"
    v-bind="$attrs"
    :headers="innerHeaders"
    :items="filteredItems"
    :titleClass="titleClass"
    :itemsPerPage="itemsPerPage"
    v-on="$listeners"
  >
    <template v-for="(_, slot) of $scopedSlots" #[slot]="props">
      <slot :name="slot" v-bind="props" />
    </template>
  </BaseTable>
</template>
