var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    attrs: {
      "value": _vm.visible,
      "open-on-click": false,
      "close-on-click": false,
      "close-on-content-click": false,
      "activator": _vm.activator,
      "offset-y": ""
    }
  }, [_c('v-form', {
    ref: "eventUpsertMenuForm",
    attrs: {
      "lazy-validation": ""
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onUpsert.apply(null, arguments);
      }
    }
  }, [_c('v-card', [_vm.isEditing ? _c('v-card-title', [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("mdi-calendar-edit")]), _vm._v(" Rediger oppføring")], 1) : _c('v-card-title', [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("mdi-calendar-plus")]), _vm._v(" Ny oppføring")], 1), _c('v-card-text', [_c('v-text-field', {
    staticClass: "required",
    attrs: {
      "label": "Tittel",
      "rules": _vm.validateNotEmpty
    },
    model: {
      value: _vm.formData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  }), _c('div', {
    staticClass: "required"
  }, [_c('BaseDatePicker', {
    attrs: {
      "label": "Dato",
      "rules": _vm.validateNotEmpty,
      "min": _vm.startDate.toISOString(),
      "max": _vm.endDate.toISOString()
    },
    model: {
      value: _vm.formData.date,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "date", $$v);
      },
      expression: "formData.date"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-baseline"
  }, [_c('v-text-field', {
    staticClass: "flex-grow-1 mr-2 required",
    attrs: {
      "type": "time",
      "label": "Fra kl.:",
      "rules": _vm.validateNotEmpty
    },
    model: {
      value: _vm.formData.startTime,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "startTime", $$v);
      },
      expression: "formData.startTime"
    }
  }), _c('v-text-field', {
    staticClass: "flex-grow-1 required",
    attrs: {
      "type": "time",
      "label": "Til kl.:",
      "rules": _vm.validateNotEmpty,
      "dataCy": "scheduleModalEndsAt"
    },
    model: {
      value: _vm.formData.endTime,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "endTime", $$v);
      },
      expression: "formData.endTime"
    }
  })], 1)], 1), _c('v-divider'), _c('v-card-actions', [!_vm.hasMemberOrgAccess ? _c('v-btn', {
    attrs: {
      "type": "submit",
      "color": "primary"
    }
  }, [_vm._v("Lagre")]) : _vm._e(), _vm.formData.id && !_vm.hasMemberOrgAccess ? _c('v-btn', {
    attrs: {
      "color": "error"
    },
    on: {
      "click": _vm.onDelete
    }
  }, [_vm._v("Slett")]) : _vm._e(), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.onCancel
    }
  }, [_vm._v("Avbryt")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }