var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-stepper', {
    staticClass: "elevation-0",
    attrs: {
      "rounded": "lg"
    },
    model: {
      value: _vm.currentStep,
      callback: function callback($$v) {
        _vm.currentStep = $$v;
      },
      expression: "currentStep"
    }
  }, [_c('v-stepper-header', {
    staticClass: "elevation-0"
  }, [_vm._l(_vm.availableSteps, function (step, idx) {
    return [_c('v-stepper-step', {
      key: "".concat(idx, "-step"),
      attrs: {
        "complete": _vm.currentStep > idx + 1,
        "step": idx + 1,
        "data-cy": "".concat(step.name, "Step")
      }
    }, [_vm._v(" " + _vm._s(step.name) + " ")]), idx + 1 < _vm.steps ? _c('v-divider', {
      key: idx
    }) : _vm._e()];
  })], 2), _c('v-stepper-items', [_vm._l(_vm.availableSteps, function (step, idx) {
    return [_c('v-stepper-content', {
      key: "".concat(idx, "-content"),
      attrs: {
        "step": idx + 1
      }
    }, [_c('div', {
      staticStyle: {
        "min-height": "66vh",
        "transform": "translateX(0%)"
      }
    }, [_vm._t(step.slotName)], 2), _c('v-container', {
      attrs: {
        "fluid": ""
      }
    }, [_c('v-row', [idx !== 0 ? _c('v-btn', {
      attrs: {
        "text": "",
        "data-cy": "previousStepStepperBtn-".concat(idx)
      },
      on: {
        "click": function click($event) {
          return _vm.previouStep(step.id);
        }
      }
    }, [_vm._v(" Forrige ")]) : _vm._e(), idx + 1 !== _vm.steps ? _c('v-btn', {
      attrs: {
        "color": "primary",
        "data-cy": "nextStepStepperBtn-".concat(idx)
      },
      on: {
        "click": function click($event) {
          return _vm.nextStep(step.id);
        }
      }
    }, [_vm._v(" Neste ")]) : _vm.isPost ? _c('v-btn', {
      attrs: {
        "color": "primary",
        "loading": _vm.loading,
        "data-cy": "postStepperBtn-".concat(idx),
        "disabled": _vm.disablePostButton
      },
      on: {
        "click": _vm.postAction
      }
    }, [_vm._v(" " + _vm._s(_vm.postBtn) + " ")]) : _vm._e(), _c('v-spacer'), _vm.isDraft ? _c('v-btn', {
      attrs: {
        "color": "primary",
        "loading": _vm.loading,
        "data-cy": "draftStepperBtn-".concat(idx)
      },
      on: {
        "click": _vm.draftAction
      }
    }, [_vm._v(_vm._s(_vm.draftBtn))]) : _vm._e(), _c('v-btn', {
      attrs: {
        "text": "",
        "data-cy": "cancelStepperBtn-".concat(idx)
      },
      on: {
        "click": _vm.cancelStepper
      }
    }, [_vm._v("Avbryt")])], 1)], 1)], 1)];
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }