var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "form",
    attrs: {
      "disabled": _vm.isCourseDone
    },
    on: {
      "close": function close($event) {
        _vm.showInfoModal = false;
      }
    }
  }, [_vm.showInfoModal ? _c('BaseModal', [_c('WebInfoModal', {
    attrs: {
      "headline": "Web-info fra studieplan og ".concat(_vm.isVocationalSchool ? 'utdanningsplan' : 'kursplan', " "),
      "courseValues": _vm.courseValues
    },
    on: {
      "close": function close($event) {
        _vm.showInfoModal = false;
      }
    }
  })], 1) : _vm._e(), _vm.courseValues.webDescription ? _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "block": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.showInfoModal = true;
      }
    }
  }, [_vm._v("Vis all web-informasjon")]), _c('div', {
    staticClass: "section-container"
  }, [_c('v-menu', {
    attrs: {
      "offset-y": "",
      "transition": "slide-x-transition",
      "close-on-content-click": false
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v("Velg Webkategorier")])];
      }
    }], null, false, 3940028552)
  }, [_c('v-list', {
    staticClass: "custom-menu"
  }, _vm._l(_vm.newStructureCourseCategories, function (item, index) {
    var _item$subCategories;

    return _c('v-list-item', {
      key: index,
      staticClass: "content-item"
    }, [_c('v-list-item-content', [(_item$subCategories = item.subCategories) !== null && _item$subCategories !== void 0 && _item$subCategories.length ? _c('v-menu', {
      attrs: {
        "offset-y": "",
        "close-on-content-click": false
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref2) {
          var on = _ref2.on,
              attrs = _ref2.attrs;
          return [_c('v-list-item-title', {
            on: {
              "click": function click($event) {
                item.selected ? _vm.removeCategory(item) : _vm.addCategory(item);
              }
            }
          }, [_c('strong', [_vm._v(" " + _vm._s(item.name) + " ")]), _c('v-icon', _vm._g(_vm._b({
            staticClass: "chevron"
          }, 'v-icon', attrs, false), on), [_vm._v("mdi-chevron-right")]), item.selected ? _c('v-icon', {
            staticStyle: {
              "margin-left": "auto"
            }
          }, [_vm._v("mdi-close")]) : _vm._e()], 1)];
        }
      }], null, true)
    }, [_c('v-list', _vm._l(item.subCategories, function (subItem, subIndex) {
      return _c('v-list-item', {
        key: subIndex,
        staticClass: "content-item"
      }, [_c('v-list-item-title', {
        on: {
          "click": function click($event) {
            subItem.selected ? _vm.removeCategory(subItem) : _vm.addCategory(subItem);
          }
        }
      }, [_vm._v(_vm._s(subItem.name) + " "), subItem.selected ? _c('v-icon', {
        staticStyle: {
          "margin-left": "auto"
        }
      }, [_vm._v("mdi-close")]) : _vm._e()], 1)], 1);
    }), 1)], 1) : _c('v-list-item-title', {
      on: {
        "click": function click($event) {
          item.selected ? _vm.removeCategory(item) : _vm.addCategory(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " "), item.selected ? _c('v-icon', {
      staticStyle: {
        "margin-left": "auto"
      }
    }, [_vm._v("mdi-close")]) : _vm._e()], 1)], 1)], 1);
  }), 1)], 1), _c('v-combobox', {
    attrs: {
      "multiple": "",
      "items": _vm.selectedCategories,
      "label": "Valgte Webkategorier *",
      "item-text": "name",
      "item-value": "slug",
      "rules": _vm.validateArrayNotEmpty,
      "clearable": "",
      "small-chips": ""
    },
    on: {
      "change": _vm.refreshSelected
    },
    model: {
      value: _vm.courseValues.webDescription.webCategories,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues.webDescription, "webCategories", $$v);
      },
      expression: "courseValues.webDescription.webCategories"
    }
  }), _c('BaseDatePicker', {
    attrs: {
      "rules": _vm.validateNotEmpty,
      "label": "Publiseringsdato *",
      "dataCy": "coursePublishDate"
    },
    model: {
      value: _vm.courseValues.webDescription.publishDate,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues.webDescription, "publishDate", $$v);
      },
      expression: "courseValues.webDescription.publishDate"
    }
  }), _c('BaseDatePicker', {
    attrs: {
      "min": _vm.minDate,
      "rules": _vm.validateNotEmpty,
      "label": "Avpubliseringsdato *",
      "dataCy": "courseUnpublishDate"
    },
    model: {
      value: _vm.courseValues.webDescription.unpublishDate,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues.webDescription, "unpublishDate", $$v);
      },
      expression: "courseValues.webDescription.unpublishDate"
    }
  }), _c('v-autocomplete', {
    staticClass: "capitalize",
    attrs: {
      "rules": _vm.validateNotEmpty,
      "items": _vm.enrollmentCourseOptions,
      "label": _vm.isVocationalSchool ? 'Søknadsstatus utdanning *' : 'Påmeldingsstatus kurs *',
      "data-cy": "enrollmentStatus"
    },
    model: {
      value: _vm.courseValues.webDescription.enrollmentStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues.webDescription, "enrollmentStatus", $$v);
      },
      expression: "courseValues.webDescription.enrollmentStatus"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "rules": _vm.validateNotEmpty,
      "items": _vm.webApplications,
      "label": _vm.isVocationalSchool ? 'Søknadsskjema *' : 'Påmeldingsskjema *',
      "item-text": "name",
      "data-cy": "applicationForm",
      "item-value": "id"
    },
    model: {
      value: _vm.courseValues.webDescription.templateId,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues.webDescription, "templateId", $$v);
      },
      expression: "courseValues.webDescription.templateId"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "rules": _vm.validateNotEmpty,
      "items": _vm.enrollmentStudentOptions,
      "label": _vm.isVocationalSchool ? 'Søknadsstatus student *' : 'Påmeldingsstatus deltaker *',
      "data-cy": "defaultEnrollmentStudentStatus"
    },
    model: {
      value: _vm.courseValues.webDescription.defaultEnrollmentStudentStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues.webDescription, "defaultEnrollmentStudentStatus", $$v);
      },
      expression: "courseValues.webDescription.defaultEnrollmentStudentStatus"
    }
  }), _c('v-switch', {
    attrs: {
      "inset": "",
      "label": "".concat(_vm.isVocationalSchool ? 'Utdanningen ' : 'Kurset ').concat(_vm.courseValues.webDescription.documentationRequired ? 'har krav til dokumentasjon *' : 'har ikke krav til dokumentasjon *')
    },
    model: {
      value: _vm.courseValues.webDescription.documentationRequired,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues.webDescription, "documentationRequired", $$v);
      },
      expression: "courseValues.webDescription.documentationRequired"
    }
  })], 1), _c('Textfields', {
    attrs: {
      "activePanel": _vm.activePanel,
      "panelLabels": _vm.needToFillPanel,
      "isCourseDone": _vm.isCourseDone
    },
    model: {
      value: _vm.courseValues.webDescription,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues, "webDescription", $$v);
      },
      expression: "courseValues.webDescription"
    }
  })], 1) : _c('h1', [_vm._v("Web steg info mangler, dette er en feil")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }