
import { api } from "@/api/api";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import BaseSheetField from "@/components/shared/sheet/BaseSheetField.vue";
import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { calculateTotalCourseHours, getCourseAreaNameById } from "@/shared/helpers/courseHelpers";
import { isVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { formatRelative } from "@/shared/helpers/dateHelpers";
import { loadingRef } from "@/shared/helpers/refHelpers";
import { useStore } from "@/shared/useHelpers";
import { CourseDto } from "@/store/modules/courses/interfaces/Course.interface";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, Ref, ref } from "@vue/composition-api";

export default defineComponent({
  name: "WebInfoModal",
  components: { BaseModalForm, BaseSheetField },
  props: {
    headline: {
      type: String,
      required: true,
    },
    courseValues: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore<StoreState>();
    const curriculum = computed(() => store.state.courses.curriculum);
    const isMainCourseVocationalSchool = computed(() =>
      isVocationalSchool(store.state.courses.curriculum.mainCourseId)
    );

    const displayWebInfoModalType = ModalType.Display;

    const courseValues = useAutoDeepCloneProp<CourseDto>(props, "courseValues");

    const courseSupervisorInfo = ref();
    const courseContactInfo = ref();
    const generalCourseInfo = ref();
    const isLoading = loadingRef(true);

    onMounted(() => {
      const { contactId, courseSupervisorId } = courseValues.value;
      getEmployeeByIdAndPopulateEmployeeInfo(courseContactInfo, isLoading, contactId);
      getEmployeeByIdAndPopulateEmployeeInfo(courseSupervisorInfo, isLoading, courseSupervisorId);
      setGeneralCourseInfo();
    });

    const setGeneralCourseInfo = async () => {
      isLoading.value = true;

      const [mainCourseName, courseName, courseLocationName] = await Promise.all([
        getCourseAreaNameById(curriculum.value.mainCourseId),
        getCourseAreaNameById(curriculum.value.subCourseId),
        getCourseLocationNameById(courseValues.value.courseLocation?.areaId),
      ]);

      generalCourseInfo.value = [
        {
          label: "Hovedområde",
          value: mainCourseName,
        },
        { label: "Kursområde", value: courseName },
        {
          label: isMainCourseVocationalSchool ? "Studiested" : "Kurssted",
          value: courseLocationName,
        },
        {
          label: "Startdato",
          value: formatRelative(courseValues.value.startDate ?? ""),
        },
        { label: "Sluttdato", value: formatRelative(courseValues.value.endDate ?? "") },
        { label: "Totalt antall timer", value: calculateTotalCourseHours(courseValues.value) },
        {
          label: "Pris",
          value: `${courseValues.value.economy?.price} ,-`,
        },
        {
          label: isMainCourseVocationalSchool ? "Søknadsfrist" : "Påmeldingsfrist",
          value: courseValues.value.enrollmentDeadline,
        },
      ];
      if (!isMainCourseVocationalSchool) {
        generalCourseInfo.value.push({
          label: isMainCourseVocationalSchool ? "Studieansvarlig" : "Kursansvarlig",
          value: courseValues.value.courseSupervisorId,
        });
      }
      isLoading.value = false;
    };

    return {
      curriculum,
      generalCourseInfo,
      displayWebInfoModalType,
      courseSupervisorInfo,
      courseContactInfo,
      isMainCourseVocationalSchool,
    };
  },
});

const getCourseLocationNameById = async (id?: number | null) => {
  if (!id) {
    return;
  }
  return (await api.area.getAreaByIdAsync(id)).data.place;
};

const getEmployeeByIdAndPopulateEmployeeInfo = async (
  listEmployeeInfo: Ref<{ label: string; value: string }[]>,
  isLoading: Ref<boolean>,
  id?: number
) => {
  if (!id) {
    return;
  }
  isLoading.value = true;
  const employee = await api.user.getUserAsync(id);
  listEmployeeInfo.value = [
    {
      label: "Navn",
      value: `${employee.data?.firstName} ${employee.data?.lastName}`,
    },
    //  TODO - Update with email adress and phone number when ready
    {
      label: "E-post",
      value: "Oppdateres når api er klart",
    },
    {
      label: "Telefonnummer",
      value: "Oppdateres når api er klart",
    },
  ];
  isLoading.value = false;
};
