var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "mx-2",
          attrs: {
            "data-cy": _vm.dataCy,
            "fab": "",
            "dark": "",
            "small": "",
            "color": _vm.color
          },
          on: {
            "click": function click($event) {
              return _vm.handleClick($event);
            }
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "dark": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.icon) + " ")])], 1)];
      }
    }])
  }, [_c('span', [_vm._v(_vm._s(_vm.btnText))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }