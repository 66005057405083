import { StoreState } from "@/store/store.state.interface";
import { customRef } from "@vue/composition-api";
import { LoadingType } from "../enums/loading-type.enum";
import { useStore } from "../useHelpers";
import { setShowLoadingSpinner, setShowSkeletonLoadingTable } from "./store.helpers";

export const loadingRef = (isLoading: boolean, loadingType: LoadingType = LoadingType.Spinner) => {
  const store = useStore<StoreState>();
  // TODO set loading spinner or skeleton based on initial state
  return customRef((track, trigger) => ({
    get() {
      track();
      return isLoading;
    },
    set(newValue: boolean) {
      isLoading = newValue;
      if (loadingType === LoadingType.Spinner) {
        setShowLoadingSpinner(store, newValue);
      }
      if (loadingType === LoadingType.SkeletonTable) {
        setShowSkeletonLoadingTable(store, newValue);
      }
      trigger();
    },
  }));
};
