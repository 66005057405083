
import FormElement from "@/components/shared/form/FormElement.vue";
import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { TeachingMethods } from "@/shared/enums/teaching-methods.enum";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { validateNotEmpty, validateCourseHourInput } from "@/shared/helpers/validationHelpers";
import { useStore } from "@/shared/useHelpers";
import { CourseDto } from "@/store/modules/courses/interfaces/Course.interface";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, Ref } from "@vue/composition-api";

export default defineComponent({
  name: "CourseTeachingHours",
  components: { FormElement },
  props: {
    value: {
      type: Object,
      required: true,
    },
    teachingMethod: {
      type: String,
      required: true,
      default: "",
    },
  },
  setup(props, { emit }) {
    const store = useStore<StoreState>();

    const courseValues: Ref<CourseDto> = useAutoDeepCloneProp<CourseDto>(props, "value");
    const curriculum = computed(() => store.state.courses.curriculum);
    const isTeacherHoursRequired = computed(
      () =>
        ![TeachingMethods.Nettkurs].includes(
          store.state.courses.curriculum.teachingMethod.toLocaleLowerCase() as TeachingMethods
        )
    );

    const totalStudyLoad = computed(() => {
      const { hoursWithInstructor, hoursWithoutInstructor, hoursOnline, hoursSelfStudy } = courseValues.value;
      const sum = [hoursWithInstructor, hoursWithoutInstructor, hoursOnline, hoursSelfStudy].reduce((sum, value) => {
        if (value === undefined || value === null) {
          return sum;
        }
        if (sum === undefined || sum === null) {
          return 0;
        }
        return +sum + +value;
      }, 0);
      return sum;
    });

    const minMaxHoursWithInstructor = [
      (value: number) =>
        (curriculum.value.hoursMin && value >= curriculum.value.hoursMin) ||
        `Minimum antall timer er ${curriculum.value.hoursMin}`,
      (value: number) =>
        (curriculum.value.hoursMax && value <= curriculum.value.hoursMax) ||
        `Maksimalt antall timer er ${curriculum.value.hoursMax}`,
    ];

    const emitChanges = () => {
      emit("input", deepCloneObject(courseValues.value));
    };

    return {
      courseValues,
      curriculum,
      totalStudyLoad,
      validateNotEmpty,
      validateCourseHourInput,
      minMaxHoursWithInstructor,
      executionPlan: computed(() => store.state.execution.executionPlan),
      emitChanges,
      isTeacherHoursRequired,
      TeachingMethods,
    };
  },
});
