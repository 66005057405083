
import BaseDatePicker from "@/components/shared/date/BaseDatePicker.vue";
import FormElement from "@/components/shared/form/FormElement.vue";
import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { runStoreAction } from "@/shared/helpers/store.helpers";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { useStore } from "@/shared/useHelpers";
import { Area } from "@/store/modules/area/interfaces/Area.interface";
import { Location } from "@/store/modules/area/interfaces/location.interface";
import { Room } from "@/store/modules/area/interfaces/Room.interface";
import { CourseDto } from "@/store/modules/courses/interfaces/Course.interface";
import { StoreActions } from "@/store/store.actions";
import { StoreModules } from "@/store/store-modules.enum";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, Ref, ref } from "@vue/composition-api";
import { isVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { TeachingMethods } from "@/shared/enums/teaching-methods.enum";
import { api } from "@/api/api";
import { ApiGetStudyLocationDto } from "@/api/generated/Api";
import { localeSortByProperty } from "@/shared/helpers/arrayHelpers";

export default defineComponent({
  name: "CourseLocationInfo",
  components: { FormElement, BaseDatePicker },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore<StoreState>();

    const roomList = ref<Room[]>([]);
    const courseValues: Ref<CourseDto> = useAutoDeepCloneProp<CourseDto>(props, "value");
    const locationList = computed<Location[]>(() => store.state.area.locations.filter((location) => location.isActive));
    const isLocationRequired = computed(
      () =>
        ![TeachingMethods.Nettkurs, TeachingMethods.VirtueltKlasserom].includes(
          store.state.courses.curriculum.teachingMethod.toLocaleLowerCase() as TeachingMethods
        )
    );
    const studyLocations = ref<ApiGetStudyLocationDto[]>();

    onMounted(async () => {
      if (!courseValues.value.courseLocation) {
        return;
      }
      const { areaId, locationId } = courseValues.value.courseLocation;
      if (areaId) {
        await runStoreAction(store, StoreModules.Area, StoreActions.AreaActions.FetchLocations, areaId);
      }
      if (locationId) {
        updateRoomList();
      }
      studyLocations.value = await getStudyLocations();
    });

    const getStudyLocations = async () =>
      (await api.studylocation.getStudyLocationsPerOrganizationAsync()).data.sort(localeSortByProperty("name"));

    const updateLocationList = (value: any) => {
      runStoreAction(store, StoreModules.Area, StoreActions.AreaActions.FetchLocations, value);
      emitChanges();
    };

    const updateRoomList = () => {
      const correctLocation = locationList.value.find((currentLocation) => {
        if (courseValues.value.courseLocation && +currentLocation.id === courseValues.value.courseLocation.locationId) {
          return currentLocation.rooms.filter((rom) => rom.isActive);
        }
      });
      if (correctLocation) {
        roomList.value = correctLocation.rooms;
      }
      emitChanges();
    };

    const emitChanges = () => {
      emit("input", deepCloneObject(courseValues.value));
    };

    return {
      courseValues,
      locationList,
      roomList,
      validateNotEmpty,
      updateLocationList,
      updateRoomList,
      isVocationalSchool: computed(() => isVocationalSchool(store.state.courses.curriculum.mainCourseId)),
      executionPlan: computed(() => store.state.execution.executionPlan),
      areas: computed<Area[]>(() => store.state.area.areas),
      emitChanges,
      isLocationRequired,
      studyLocations,
    };
  },
});
