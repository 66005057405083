
import { api } from "@/api/api";
import { ApiGetEmployeeDto } from "@/api/generated/Api";
import { debounceHelper } from "@/shared/debounce";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { openNotification } from "@/shared/helpers/store.helpers";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, onMounted, ref } from "@vue/composition-api";

const SEARCH_PAGE_SIZE = 20;
const QUERY_TEXT_MIN_LENGTH = 3;

export default defineComponent({
  name: "EmployeeSearchInput",
  props: {
    label: {
      type: String,
      required: true,
    },
    mandatory: {
      type: Boolean,
      required: false,
      default: true,
    },
    cypressId: {
      type: String,
      required: false,
    },
    value: {
      type: Number,
      required: false,
    },
  },
  emits: ["change"],
  setup(props) {
    const store = useStore<StoreState>();

    const employees = ref<ApiGetEmployeeDto[]>([]);
    const isLoading = ref(false);
    const itemText = (employee: ApiGetEmployeeDto) =>
      `${employee.firstName || "(fornavn mangler)"} ${employee.lastName || "(etternavn mangler)"}`.trim();

    onMounted(async () => {
      if (props.value) {
        // Search all users as getEmployeesAsync does not allow search by ID
        const employee = (await api.user.getUserAsync(props.value)).data;
        employees.value = [{ ...employee, organizationId: -1 }];
      }
    });

    const searchEmployee = async (queryText?: string) => {
      if (!queryText || queryText.length < QUERY_TEXT_MIN_LENGTH) {
        return;
      }

      const alreadyMatched = employees.value.some((employee) => queryText === itemText(employee));
      if (alreadyMatched) {
        // The update:search-input event is triggered when selecting a matching user,
        // this prevents another unnecessary search from being performed
        return;
      }

      await debounceHelper.debounce(`searchEmployee-${props.cypressId || props.label}`, 500);

      const [firstName, ...middleAndLastName] = queryText.split(/\s+/);
      const lastName = middleAndLastName.pop(); // Discard any middle names (not supported by backend)

      try {
        isLoading.value = true;
        employees.value = await searchEmployeesByName(firstName, lastName);
        sortEmployees(employees.value);

        if (!employees.value.length) {
          openNotification(
            store,
            NotificationItemType.Warning,
            `Fant ingen personer med fornavn/etternavn som matcher "${queryText}"`
          );
        }
      } catch (err) {
        openNotification(store, NotificationItemType.Error, "En feil oppsto ved søk etter person");
      } finally {
        isLoading.value = false;
      }
    };

    return {
      employees,
      isLoading,
      itemText,
      validateNotEmpty,
      searchEmployee,
      employeeFilter: () => true,
      minLength: QUERY_TEXT_MIN_LENGTH,
    };
  },
});

/** Sort employee array (in-place) by first name and last name */
const sortEmployees = (employeeList: ApiGetEmployeeDto[]) =>
  employeeList.sort((a, b) => {
    const compareA = `${a.firstName ?? ""} ${a.lastName ?? ""}`.trim();
    const compareB = `${b.firstName ?? ""} ${b.lastName ?? ""}`.trim();
    return compareA.localeCompare(compareB);
  });

/** Search employees by first name and (optional) last name */
const searchEmployeesByName = async (firstName: string, lastName?: string) => {
  // Query returns results if firstName matches, or if *both* firstName and
  // lastName match (only if lastName is set)
  let results = (
    await api.user.getEmployeesAsync({
      FirstName: firstName,
      LastName: lastName,
      PageSize: SEARCH_PAGE_SIZE,
    })
  ).data;

  if (results.length < 3 && !lastName) {
    // Only one name is provided, and the previous query by only first name
    // returned no matches - attempt query by only last name instead
    const fallbackResults = (
      await api.user.getEmployeesAsync({
        LastName: firstName,
        PageSize: 20,
      })
    ).data;

    if (fallbackResults.length) {
      // Return unique result
      results = [...new Set([...fallbackResults, ...results])];
    }
  }

  return results;
};
