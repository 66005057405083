
import { api } from "@/api/api";
import { ApiCreateCourseDto, ApiGetCourseDto, ApiUpdateCourseDto } from "@/api/generated/Api";
import UploadCourseFile from "@/components/course/new/files/UploadCourseFile.vue";
import NewCourseEconomyStepVue from "@/components/course/new/steps/economy/NewCourseEconomyStep.vue";
import NewCourseInfoStepVue from "@/components/course/new/steps/info/NewCourseInfoStep.vue";
import NewCourseTeachingMaterialStepVue from "@/components/course/new/steps/NewCourseTeachingMaterialStep.vue";
import NewCourseTestStepVue from "@/components/course/new/steps/NewCourseTestStep.vue";
import NewCoursePlanStepVue from "@/components/course/new/steps/plan/NewCoursePlanStep.vue";
import NewCourseWebStepVue from "@/components/course/new/steps/web/NewCourseWebStep.vue";
import BaseStepperDynamic from "@/components/shared/stepper/BaseStepperDynamic.vue";
import { CourseCreationType } from "@/shared/enums/courseCreationType.enum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification, runStoreAction } from "@/shared/helpers/store.helpers";
import { useRoute, useRouter, useStore } from "@/shared/useHelpers";
import { getCourseInitialState } from "@/store/modules/courses/course.initial-state";
import { CourseDto } from "@/store/modules/courses/interfaces/Course.interface";
import { ExecutionStoreState } from "@/store/modules/execution/execution.store.interface";
import { Curriculum } from "@/store/modules/plans/interfaces/Curriculum.interface";
import { StoreModules } from "@/store/store-modules.enum";
import { StoreActions } from "@/store/store.actions";
import { StoreState } from "@/store/store.state.interface";
import { computed, ComputedRef, defineComponent, onMounted, Ref, ref, watch } from "@vue/composition-api";
import { Store } from "vuex";
import { isVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { LinkedCourseType } from "@/shared/enums/linkedCourseType.enum";
import { useNavigateBack } from "@/shared/helpers/navigationHelpers";
import { CourseRouteNames } from "@/shared/enums/RouteNames/courseRouteNames.enum";
import { CourseStatus } from "@/shared/enums/CourseStatus.enum";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";

export default defineComponent({
  name: "CourseUpsertStepperPage",
  components: { BaseStepperDynamic, UploadCourseFile, NewCourseInfoStepVue, NewCoursePlanStepVue },
  setup(props, context) {
    const store = useStore<StoreState>();
    const route = useRoute();
    const router = useRouter();
    const courseMode = ref(CourseCreationType.Create);
    const editCourseId = +route.params.editCourseId;
    const initDone = ref(false);
    const loading = ref(false);
    const hasSubmitted = ref(false);
    const initialState = getCourseInitialState(store.state.hoc.usePreFilledValues);
    const curriculumId = computed(() => {
      if (courseMode.value === CourseCreationType.Create) {
        return +route.params.curriculumId;
      }
      return courseValues.value.curriculumId;
    });

    const getCourseResponse: Ref<ApiGetCourseDto | undefined> = ref<ApiGetCourseDto>();

    const displaySubmitModal = ref(false);
    const modalTitle = computed(() => (editCourseId ? "Last opp filer" : "Bekreft kursopprettelse"));
    const executionPlan = computed(() => store.state.execution.executionPlan);
    const courseValues: Ref<CourseDto> = ref(initialState);
    const courseIsVocationalSchool = computed(() => isVocationalSchool(store.state.courses.curriculum.mainCourseId));
    const isCourseOrderable = ref(courseValues.value.isOrderable);

    onMounted(async () => {
      if (!editCourseId) {
        courseMode.value = CourseCreationType.Create;
      } else {
        courseMode.value = CourseCreationType.Edit;
        await setupEditValues();
      }
      await loadDefaultValues(store, curriculumId.value);

      initDone.value = true;
    });

    const setupEditValues = async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        const course = await api.course.getCourseByIdFilteredByDepartmentAsync(editCourseId);
        getCourseResponse.value = course.data;
        courseValues.value = {
          ...course.data,
          plan: {
            schoolRouteId: course.data.plan?.schoolRouteId ?? 0,
            registerHours: course.data.plan?.registerHours ?? false,
            schedules: course.data.plan?.schedules ?? [],
          },
        };
      });
    };

    const cancel = () => {
      useNavigateBack(CourseRouteNames.CourseList);
    };

    const cancelSubmitModal = () => {
      displaySubmitModal.value = false;
      loading.value = false;
    };

    const createOrEditStudyplan = () => {
      if (courseMode.value === CourseCreationType.Edit) {
        updateCourse();
      } else {
        displaySubmitModal.value = true;
      }
    };

    const nextStep = () => {
      window.scrollTo({ top: 0 });
    };

    const updateCourse = async () => {
      if (!getCourseResponse.value || !getCourseResponse.value.status) {
        const courseTerminology = courseIsVocationalSchool.value ? "Studie" : "Kurs";
        openNotification(
          store,
          NotificationItemType.Error,
          `${courseTerminology} har ikke lastet eller mangler status`
        );
        return;
      }
      await globalLoadingWrapper({ blocking: true }, async () => {
        const updateValue: ApiUpdateCourseDto = {
          ...courseValues.value,
        };

        // Set webdescription to undefined if the course has no web description
        if (courseValues.value.isOrderable) {
          updateValue.webDescription = undefined;
        }

        try {
          // Set loading when updating or posting new course
          loading.value = true;
          await api.course.updateCourseAsync(editCourseId, updateValue);
          const courseTerminology = courseIsVocationalSchool.value ? "studie" : "kurs";
          openNotification(store, NotificationItemType.Success, `Redigering av ${courseTerminology} vellykket`);
          hasSubmitted.value = true; // To ensure that the form cannot be submitted multiple times if routing fails
          displaySubmitModal.value = false;
          router.push({
            name: SingleCourseRouteNames.CourseDashboard,
            params: {
              id: editCourseId.toString(),
            },
          });
        } catch (err: any) {
          displayApiValidationErrors(store, err.response.data);
        } finally {
          loading.value = false;
        }
      });
    };

    const createCourse = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const createValue: ApiCreateCourseDto = deepCloneObject(courseValues.value);
        createValue.curriculumId = curriculumId.value;
        if (!executionPlan.value.economyRequired) {
          delete createValue.economy;
        }
        try {
          // Set loading when updating or posting new course
          loading.value = true;
          const id = await getResponse(createValue);
          const courseTerminology = courseIsVocationalSchool.value ? "Studie" : "Kurset";
          openNotification(store, NotificationItemType.Success, `${courseTerminology} ble opprettet med ID${id}`);
          hasSubmitted.value = true; // To ensure that the form cannot be submitted multiple times if routing fails
          displaySubmitModal.value = false;
          router.push({
            name: SingleCourseRouteNames.CourseDashboard,
            params: {
              id: id.toString(),
            },
          });
        } catch (err: any) {
          displayApiValidationErrors(store, err.response.data);
          displaySubmitModal.value = false;
        } finally {
          loading.value = false;
        }
      });
    };

    const getResponse = async (createValue: ApiCreateCourseDto) => {
      if (route.query.linkedCourse && route.query.linkedCourseType === LinkedCourseType.Grant) {
        const response = await api.course.createFacilitationCourseAsync(+route.query.linkedCourse, createValue);
        return response.data.id;
      }
      const response = await api.course.createCourseAsync(createValue);
      return response.data.id;
    };

    const selectedSteps = computed(() =>
      getSelectedSteps(context.refs, executionPlan, courseIsVocationalSchool.value, isCourseOrderable.value)
    );

    const curriculum = computed(() => store.state.courses.curriculum);
    setWebDescription(courseValues, curriculum);

    const courseValuesChanged = (courseValues?: CourseDto) => {
      isCourseOrderable.value = courseValues?.isOrderable ?? true;
    };

    return {
      courseMode,
      displaySubmitModal,
      modalTitle,
      selectedSteps,
      initDone,
      courseValues,
      loading,
      hasSubmitted,
      cancel,
      cancelSubmitModal,
      createCourse,
      executionPlan,
      createOrEditStudyplan,
      nextStep,
      isCourseEdit: computed(() => courseMode.value === CourseCreationType.Edit),
      courseId: editCourseId ? editCourseId : undefined,
      isCourseOrderable,
      isCourseDone: computed(() => getCourseResponse.value?.status === CourseStatus.Closed),
      courseValuesChanged,
      curriculumId,
      curriculum,
    };
  },
});

function setWebDescription(courseValues: Ref<CourseDto>, curriculum: Ref<Curriculum>) {
  const updateWebDesription = () => {
    courseValues.value.webDescription = {
      ...courseValues.value.webDescription,
      title: courseValues.value.webDescription?.title ? courseValues.value.webDescription.title : curriculum.value.name,
      templateId: courseValues.value.webDescription?.templateId ?? "",
      publishDate: courseValues.value.webDescription?.publishDate ?? "",
      targetGroup: courseValues.value.webDescription?.targetGroup
        ? courseValues.value.webDescription.targetGroup
        : curriculum.value.targetGroup,
      foreknowledgeRequirements: courseValues.value.webDescription?.foreknowledgeRequirements
        ? courseValues.value.webDescription.foreknowledgeRequirements
        : curriculum.value.foreknowledgeRequirements,
      documentationRequired: courseValues.value.webDescription?.documentationRequired
        ? courseValues.value.webDescription.documentationRequired
        : curriculum.value.courseCertificateRequired,
      learningObjectives: courseValues.value.webDescription?.learningObjectives
        ? courseValues.value.webDescription.learningObjectives
        : curriculum.value.learningObjectives,
      courseContent: courseValues.value.webDescription?.courseContent
        ? courseValues.value.webDescription?.courseContent
        : curriculum.value.course.content,
      method: courseValues.value.webDescription?.method
        ? courseValues.value.webDescription?.method
        : curriculum.value.course.method,
    };
  };
  watch(curriculum, () => {
    updateWebDesription();
  });
}

function getSelectedSteps(
  refs: any,
  executionPlan: ComputedRef<ExecutionStoreState["executionPlan"]>,
  courseIsVocationalSchool: boolean,
  isOrderable: boolean
) {
  return [
    // here
    {
      name: "Info",
      isAvailable: true,
      id: 1,
      component: NewCourseInfoStepVue,
    },
    {
      name: "Plan",
      isAvailable: true,
      id: 2,
      component: NewCoursePlanStepVue,
    },
    {
      name: "Økonomi",
      isAvailable: executionPlan.value.economyRequired ?? true,
      id: 3,
      component: NewCourseEconomyStepVue,
    },
    {
      name: "Web",
      isAvailable: !isOrderable,
      id: 4,
      component: NewCourseWebStepVue,
    },
    {
      name: "Læremidler",
      isAvailable: executionPlan.value.teachingMaterialsRequired ?? true,
      id: 5,
      component: NewCourseTeachingMaterialStepVue,
    },
    {
      name: courseIsVocationalSchool ? "Vurderingsformer" : "Tester",
      isAvailable: executionPlan.value.testsRequired ?? true,
      id: 6,
      component: NewCourseTestStepVue,
    },
  ].map((step) => {
    // @ts-ignore TODO fix error
    const ref = () => refs[`step${step.id}`]?.[0];
    return new Proxy(step, {
      get(target: any, name: string) {
        return target[name] ?? ref()?.[name];
      },
    });
  });
}

const loadDefaultValues = async (store: Store<StoreState>, curriculumId: number) => {
  await globalLoadingWrapper({ blocking: true }, async () =>
    Promise.all([
      runStoreAction(store, StoreModules.Courses, StoreActions.CoursesActions.FetchCurriculum, curriculumId),
      runStoreAction(store, StoreModules.Execution, StoreActions.ExecutionActions.GetExecutionPlan, curriculumId),
      runStoreAction(store, StoreModules.Plans, StoreActions.PlansActions.FetchDefaultOptions),
      runStoreAction(store, StoreModules.Area, StoreActions.AreaActions.FetchAreas),
      runStoreAction(
        store,
        StoreModules.TeachingMaterials,
        StoreActions.TeachingMaterialsActions.FetchTeachingmaterials
      ),
      runStoreAction(store, StoreModules.Courses, StoreActions.CoursesActions.FetchCourseCategories),
      runStoreAction(store, StoreModules.Contacts, StoreActions.ContactsActions.FetchOrganisations),
    ])
  );
};

function displayApiValidationErrors(store: Store<StoreState>, response: any) {
  if (response.errors) {
    openNotification(store, NotificationItemType.Error, "Det oppstod valideringsfeil under kursopprettelse.");
  }
  if (response.fieldsErrors) {
    const errors = getErrors(response.fieldsErrors);
    errors.forEach((currentError) => {
      openNotification(store, NotificationItemType.Error, `Valideringsfeil: ${currentError[0]}`);
    });
  }
}

function getErrors(object: any[]): any[] {
  return object.reduce((model: any, current: any) => {
    if (current.errors.length > 1 && typeof current.errors[0] === "object") {
      return model.concat(getErrors(current.errors));
    }
    model.push(current.errors);
    return model;
  }, []);
}
