var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-form', {
    ref: "timeForm",
    attrs: {
      "disabled": _vm.isCourseDone,
      "lazy-validation": ""
    }
  }, [_c('v-row', {
    staticClass: "px-1 pb-2"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "3",
      "md": "3"
    }
  }, [_c('v-text-field', {
    staticClass: "required",
    attrs: {
      "type": "time",
      "rules": [].concat(_vm.validateTimeInput, _vm.validateNotEmpty),
      "label": "Klokkeslett start",
      "value": _vm.startTime
    },
    on: {
      "change": _vm.setStartTime
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "3",
      "md": "3"
    }
  }, [_c('v-text-field', {
    staticClass: "required",
    attrs: {
      "type": "time",
      "rules": [].concat(_vm.validateTimeInput, _vm.validateNotEmpty),
      "label": "Klokkeslett slutt",
      "value": _vm.endTime
    },
    on: {
      "change": _vm.setEndTime
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6",
      "md": "6"
    }
  }, [_c('v-switch', {
    attrs: {
      "inset": "",
      "label": "Vis klokkeslett på web"
    },
    model: {
      value: _vm.courseValues.isTimeWebVisible,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues, "isTimeWebVisible", $$v);
      },
      expression: "courseValues.isTimeWebVisible"
    }
  })], 1)], 1)], 1), _vm.courseValues.startDate && _vm.courseValues.endDate && _vm.hoursWithInstructor ? _c('ScheduleForm', {
    attrs: {
      "minDate": _vm.courseValues.startDate,
      "maxDate": _vm.courseValues.endDate,
      "hoursWithInstructor": _vm.hoursWithInstructor,
      "schedule": _vm.courseValues.plan.schedules,
      "hoursMin": _vm.minHours,
      "hoursMax": _vm.maxHours,
      "selectedSchoolRouteId": _vm.courseValues.plan.schoolRouteId || undefined,
      "isCourseDone": _vm.isCourseDone
    },
    on: {
      "updatePlan": _vm.handleUpdatePlan
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }