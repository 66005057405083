var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isVocationalSchool ? _c('FormElement', {
    class: {
      required: _vm.isVocationalSchool
    },
    attrs: {
      "inputFieldType": "autocomplete",
      "label": "Studiested",
      "inputItems": _vm.studyLocations,
      "itemText": "name",
      "itemValue": "id",
      "rules": _vm.isVocationalSchool ? _vm.validateNotEmpty : [],
      "dataCy": "courseStudyLocation"
    },
    on: {
      "changeEvent": _vm.emitChanges
    },
    model: {
      value: _vm.courseValues.studyLocationId,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues, "studyLocationId", $$v);
      },
      expression: "courseValues.studyLocationId"
    }
  }) : _vm._e(), _c('FormElement', {
    class: {
      required: _vm.isLocationRequired
    },
    attrs: {
      "inputFieldType": "autocomplete",
      "label": "Lokasjon",
      "inputItems": _vm.areas,
      "itemText": "place",
      "itemValue": "id",
      "rules": _vm.isLocationRequired ? _vm.validateNotEmpty : [],
      "dataCy": "courseArea"
    },
    on: {
      "changeEvent": _vm.updateLocationList,
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.courseValues.courseLocation.areaId,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues.courseLocation, "areaId", $$v);
      },
      expression: "courseValues.courseLocation.areaId"
    }
  }), _c('FormElement', {
    attrs: {
      "inputFieldType": "autocomplete",
      "label": "Adresse",
      "no-data-text": "Ingen lokaler",
      "inputItems": _vm.locationList,
      "itemText": "street",
      "itemValue": "id",
      "clearable": "",
      "dataCy": "courseLocation"
    },
    on: {
      "changeEvent": _vm.updateRoomList
    },
    model: {
      value: _vm.courseValues.courseLocation.locationId,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues.courseLocation, "locationId", $$v);
      },
      expression: "courseValues.courseLocation.locationId"
    }
  }), _c('FormElement', {
    attrs: {
      "inputFieldType": "autocomplete",
      "label": "Rom",
      "no-data-text": "Ingen rom",
      "inputItems": _vm.roomList,
      "itemText": "name",
      "itemValue": "id",
      "clearable": "",
      "dataCy": "courseRoom"
    },
    on: {
      "changeEvent": _vm.emitChanges
    },
    model: {
      value: _vm.courseValues.courseLocation.roomId,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues.courseLocation, "roomId", $$v);
      },
      expression: "courseValues.courseLocation.roomId"
    }
  }), _c('BaseDatePicker', {
    attrs: {
      "label": _vm.executionPlan.info.isCancellationDeadlineRequired ? 'Avbestillingsfrist lokale *' : 'Avbestillingsfrist lokale',
      "rules": _vm.executionPlan.info.isCancellationDeadlineRequired ? _vm.validateNotEmpty : [],
      "min": new Date().toISOString().slice(0, 10),
      "inset": ""
    },
    on: {
      "input": _vm.emitChanges
    },
    model: {
      value: _vm.courseValues.cancellationDeadline,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues, "cancellationDeadline", $$v);
      },
      expression: "courseValues.cancellationDeadline"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }