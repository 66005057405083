
import { api } from "@/api/api";
import { ApiGetApplicationFormTemplateDto, ApiUpsertCourseDescriptionDto } from "@/api/generated/Api";
import Textfields from "@/components/course/new/steps/web/Textfields.vue";
import WebInfoModal from "@/components/course/new/steps/web/WebInfoModal.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseDatePicker from "@/components/shared/date/BaseDatePicker.vue";
import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { CourseEnrollmentStatus } from "@/shared/enums/CourseEnrollmentStatus.enum";
import { CourseStatus } from "@/shared/enums/CourseStatus.enum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { ParticipantEnrollmentStatus } from "@/shared/enums/ParticipantEnrollmentStatus.enum";
import { isVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { validateNotEmpty, validateArrayNotEmpty, validateTimeInput } from "@/shared/helpers/validationHelpers";
import { useStore } from "@/shared/useHelpers";
import { CourseDto } from "@/store/modules/courses/interfaces/Course.interface";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, PropType, ref, watch } from "@vue/composition-api";
import { add } from "date-fns";
import Vue from "vue";

export interface NeedToFillPanel {
  propName: keyof ApiUpsertCourseDescriptionDto;
  label: string;
}

interface CourseCategory {
  term_id: number;
  name: string;
  slug: string;
  term_group: number;
  term_taxonomy_id: number;
  taxonomy: string;
  description: string;
  parent: number;
  count: number;
  filter: string;
  term_order: string;
  subCategories?: CourseCategory[] | undefined;
  selected?: boolean;
}

export default defineComponent({
  name: "NewCourseWebStep",
  components: { Textfields, BaseDatePicker, BaseModal, WebInfoModal },
  props: {
    value: {
      type: Object as PropType<CourseDto>,
      required: true,
    },
  },
  setup(props, context) {
    const store = useStore<StoreState>();
    const courseValues = useAutoDeepCloneProp<CourseDto>(props, "value");
    const webApplications = ref<ApiGetApplicationFormTemplateDto[]>();
    const showInfoModal = ref(false);
    const activePanel = ref(0);
    const minDate = ref();
    const courseCategories = ref<any[]>([]);
    const newStructureCourseCategories = ref<CourseCategory[]>([]);
    const selectedCategories = ref<string[]>([]);

    watch(props, () => {
      if (!props.value.enrollmentDeadline) {
        return;
      }

      minDate.value = add(new Date(props.value.enrollmentDeadline), { days: 1 });
    });

    const onStepGoBack = () => {
      // only emit on go to next or prev step
      context.emit("input", deepCloneObject(courseValues.value));
    };

    const vocationalSchool = computed(() => isVocationalSchool(store.state.courses.curriculum.mainCourseId));

    const needToFillPanel: NeedToFillPanel[] = [
      { propName: "intro", label: "Ingress" },
      { propName: "targetGroup", label: "Målgruppe" },
      {
        propName: "foreknowledgeRequirements",
        label: vocationalSchool.value ? "Opptakskrav" : "Forkunnskaper",
      },
      { propName: "learningObjectives", label: "Læringsmål" },
      { propName: "courseContent", label: "Faginnhold" },
      { propName: "method", label: "Gjennomføring" },
      { propName: "financing", label: "Finansiering" },
      { propName: "exam", label: "Eksamen" },
    ];

    const enrollmentCourseOptions = vocationalSchool.value
      ? [CourseEnrollmentStatus.Open]
      : [CourseEnrollmentStatus.Open, CourseEnrollmentStatus.Full];
    const enrollmentStudentOptions = vocationalSchool.value
      ? [ParticipantEnrollmentStatus.Applied]
      : [ParticipantEnrollmentStatus.Applied, ParticipantEnrollmentStatus.Enrolled];

    const validate = () => {
      const isValid = (context.refs.form as Vue & { validate: () => boolean }).validate();
      if (!isValid) {
        return false;
      }
      const somePanelsInvalid = needToFillPanel.some((current, index) => {
        if (courseValues.value.webDescription) {
          if (
            courseValues.value.webDescription[current.propName] === "" ||
            courseValues.value.webDescription[current.propName] === "<p></p>"
          ) {
            openNotification(store, NotificationItemType.Warning, `${current.label} må fylles inn `);
            activePanel.value = index;
            return true;
          }
          return false;
        }
      });
      if (somePanelsInvalid) {
        return false;
      }

      context.emit("input", deepCloneObject(courseValues.value));
      return true;
    };

    onMounted(() => {
      globalLoadingWrapper({ blocking: true }, async () => {
        webApplications.value = (await api.appformtemplate.getAppFormTemplatesAsync()).data.filter(
          (current) => current.isActive
        );

        courseCategories.value = store.state.courses.courseCategories;

        newStructureCourseCategories.value = courseCategories.value.map((x) => ({
          ...x,
          selected: false,
          subCategories: [],
        }));

        structureCategories();
      });
    });

    const addCategory = (item: CourseCategory): void => {
      if (selectedCategories.value.map((x) => x).includes(item.slug)) {
        openNotification(store, NotificationItemType.Warning, `${item.name} er allerede lagt til i listen`);
        return;
      }

      // Check if item is subItem
      if (item.parent) {
        const parent = newStructureCourseCategories.value.find((x) => x.term_id === item.parent);

        if (parent && !selectedCategories.value.map((x) => x).includes(parent.slug)) {
          selectedCategories.value.push(parent.slug);
        }
      }

      selectedCategories.value.push(item.slug);
      refreshSelected();
    };

    const structureCategories = (): void => {
      courseCategories?.value.forEach((x: CourseCategory) => {
        if (x.parent) {
          const parentId = x.parent;
          const parentObj = newStructureCourseCategories.value.find((x) => x.term_id === parentId);

          if (parentObj?.subCategories) {
            parentObj.subCategories.push({ ...x, selected: false }) || {};
            removeChildFromParentArray(x);
          }
        }
      });
    };

    const removeChildFromParentArray = (item: CourseCategory) => {
      const index = newStructureCourseCategories.value.findIndex((x) => x.slug === item.slug);
      if (index !== 1) {
        newStructureCourseCategories.value.splice(index, 1);
      }
    };

    const removeCategory = (item: CourseCategory) => {
      if (!item.parent) {
        const childrenSlugs = item.subCategories?.map((x) => x.slug) || [];

        childrenSlugs.forEach((x) => {
          const childIndex = selectedCategories.value.indexOf(x);
          if (childIndex !== -1) {
            selectedCategories.value.splice(childIndex, 1);
          }
        });
      }

      const itemIndex = selectedCategories.value.indexOf(item.slug);
      if (itemIndex !== -1) {
        selectedCategories.value.splice(itemIndex, 1);
      }

      refreshSelected();
    };

    const refreshSelected = () => {
      if (courseValues.value.webDescription) {
        courseValues.value.webDescription.webCategories = selectedCategories.value;
      }

      newStructureCourseCategories.value.forEach((x) => {
        x.selected = selectedCategories.value.includes(x.slug);

        x.subCategories?.forEach((z) => {
          z.selected = selectedCategories.value.includes(z.slug);
        });
      });
    };

    return {
      refreshSelected,
      enrollmentCourseOptions,
      enrollmentStudentOptions,
      courseValues,
      validateNotEmpty,
      validateTimeInput,
      activePanel,
      validate,
      showInfoModal,
      isVocationalSchool: vocationalSchool,
      courseCategories,
      webApplications,
      onStepGoBack,
      needToFillPanel,
      isCourseDone: props.value.status === CourseStatus.Closed,
      minDate,
      addCategory,
      newStructureCourseCategories,
      selectedCategories,
      removeCategory,
      validateArrayNotEmpty,
    };
  },
});
