var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('FormElement', {
    attrs: {
      "inputFieldType": "textField",
      "value": "".concat(_vm.curriculum.id, "-").concat(_vm.curriculum.name),
      "label": "Studieplan *",
      "rules": _vm.validateNotEmpty,
      "dataCy": "courseCurriculum",
      "readonly": ""
    },
    on: {
      "changeEvent": _vm.emitChanges
    }
  }), _vm.isCourseEdit ? _c('FormElement', {
    attrs: {
      "inputFieldType": "select",
      "label": "Status *",
      "inputItems": _vm.courseDefaultStatuses,
      "dataCy": "courseStatusSelect",
      "rules": _vm.validateNotEmpty
    },
    on: {
      "changeEvent": _vm.emitChanges
    },
    model: {
      value: _vm.courseValues.status,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues, "status", $$v);
      },
      expression: "courseValues.status"
    }
  }) : _vm._e(), _vm.isCourseEdit ? _c('FormElement', {
    staticStyle: {
      "grid-column": "1 / span 2"
    },
    attrs: {
      "inputFieldType": "textField",
      "label": _vm.executionPlan.info.reasonForStatusChangeRequired ? 'Begrunnelse statusendring *' : 'Begrunnelse statusendring',
      "rules": _vm.executionPlan.info.reasonForStatusChangeRequired ? _vm.validateNotEmpty : [],
      "dataCy": "reasonForStatusChange"
    },
    on: {
      "changeEvent": _vm.emitChanges
    },
    model: {
      value: _vm.courseValues.reasonForStatusChange,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues, "reasonForStatusChange", $$v);
      },
      expression: "courseValues.reasonForStatusChange"
    }
  }) : _vm._e(), _c('v-text-field', {
    staticClass: "required",
    attrs: {
      "rules": _vm.validateNotEmpty,
      "label": "Ekstern tittel",
      "data-cy": "webDescriptionTitle"
    },
    on: {
      "input": _vm.emitChanges
    },
    model: {
      value: _vm.courseValues.externalTitle,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues, "externalTitle", $$v);
      },
      expression: "courseValues.externalTitle"
    }
  }), _c('FormElement', {
    staticClass: "required",
    attrs: {
      "inputFieldType": "textField",
      "label": "Intern tittel",
      "rules": _vm.validateNotEmpty,
      "dataCy": "courseName"
    },
    on: {
      "changeEvent": _vm.emitChanges
    },
    model: {
      value: _vm.courseValues.courseName,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues, "courseName", $$v);
      },
      expression: "courseValues.courseName"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "label": "Arrangør *",
      "items": _vm.organizerOrganizations,
      "item-text": "name",
      "item-value": "id",
      "clearable": "",
      "data-cy": "organizerOrganizationAutocomplete",
      "rules": _vm.validateOrganization
    },
    on: {
      "change": _vm.emitAndCheckFeatureSettings
    },
    model: {
      value: _vm.courseValues.organizerOrganizationId,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues, "organizerOrganizationId", $$v);
      },
      expression: "courseValues.organizerOrganizationId"
    }
  }), _vm.hasMultiLevel ? _c('TheMemberOrganizaionModalTreeHierarchy', {
    attrs: {
      "memberOrgHierarchi": _vm.memberOrgHierarchi,
      "buttonLabel": "Under arrangør *"
    },
    on: {
      "update": _vm.setMemberOrg
    },
    model: {
      value: _vm.courseValues.subOrganizerOrganizationId,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues, "subOrganizerOrganizationId", $$v);
      },
      expression: "courseValues.subOrganizerOrganizationId"
    }
  }) : _vm._e(), _vm.hasMultiLevel ? _c('v-text-field', {
    attrs: {
      "label": "Valgte underarrangør",
      "readonly": ""
    },
    model: {
      value: _vm.subOrganizerName,
      callback: function callback($$v) {
        _vm.subOrganizerName = $$v;
      },
      expression: "subOrganizerName"
    }
  }) : _vm._e(), _c('v-switch', {
    attrs: {
      "label": "".concat(_vm.isVocationalSchool ? 'Utdanningen ' : 'Kurset ').concat(_vm.value.useLearningPlatform ? 'skal benytte seg av læringsplatform *' : 'skal ikke benytte seg av læringsplatform *'),
      "rules": _vm.validateBoolean,
      "inset": "",
      "data-cy": "useLearningPlatformSwitch"
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.courseValues.useLearningPlatform,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues, "useLearningPlatform", $$v);
      },
      expression: "courseValues.useLearningPlatform"
    }
  }), _c('v-switch', {
    attrs: {
      "label": _vm.courseValues.plan.registerHours ? 'Fremmøte skal registreres *' : 'Fremmøte skal ikke registreres *',
      "rules": _vm.validateBoolean,
      "data-cy": "registerHoursSwitch",
      "inset": ""
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.courseValues.plan.registerHours,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues.plan, "registerHours", $$v);
      },
      expression: "courseValues.plan.registerHours"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }