var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-dialog', _vm._b({
    attrs: {
      "value": true,
      "persistent": "",
      "width": _vm.width,
      "max-width": _vm.maxWidthFormatted
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) { return null; }
        return _vm.$emit('close');
      }
    }
  }, 'v-dialog', _vm.$attrs, false), [_vm._t("default")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }