var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('BaseTableFiltered', {
    staticClass: "mb-5",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.teachingMaterials,
      "search": _vm.search
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title font-weight-light text-center",
          staticStyle: {
            "align-self": "center"
          }
        }, [_vm._v("Læremidler")]), _c('v-spacer'), _c('v-text-field', {
          attrs: {
            "append-icon": "mdi-magnify",
            "label": "Søk",
            "single-line": "",
            "clearable": ""
          },
          model: {
            value: _vm.search,
            callback: function callback($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        })];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_vm.courseValues.additionalTeachingMaterialIds && _vm.courseValues.additionalTeachingMaterialIds.find(function (id) {
                return id === item.id;
              }) ? _c('v-icon', _vm._g(_vm._b({
                staticClass: "mr-2",
                on: {
                  "click": function click($event) {
                    return _vm.removeTeachingMaterial(item.id);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-delete ")]) : _c('v-icon', _vm._g(_vm._b({
                staticClass: "mr-2",
                attrs: {
                  "data-cy": "addTeachingMaterial"
                },
                on: {
                  "click": function click($event) {
                    return _vm.addTeachingMaterial(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-plus ")])];
            }
          }], null, true)
        }, [_vm.courseValues.additionalTeachingMaterialIds ? _c('span', [_vm._v(_vm._s(_vm.courseValues.additionalTeachingMaterialIds.find(function (id) {
          return id === item.id;
        }) ? "Fjern læremiddel" : "Legg til læremiddel"))]) : _vm._e()])];
      }
    }])
  }), _vm.curriculumTeachingMaterials.length ? _c('v-card', {
    staticClass: "mb-5",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    attrs: {
      "data-cy": "curriculumTeachingMaterialsHeading"
    }
  }, [_vm._v("Læremidler fra studieplan")]), _c('v-card-text', _vm._l(_vm.curriculumTeachingMaterials, function (teachingMaterialId, index) {
    return _c('v-chip', {
      key: index,
      staticClass: "mr-3",
      attrs: {
        "label": "",
        "data-cy": "curriculumTeachingMaterial"
      }
    }, [_vm._v(" " + _vm._s(_vm.teachingMaterialName(teachingMaterialId)) + " ")]);
  }), 1)], 1) : _vm._e(), _vm.courseValues.additionalTeachingMaterialIds && _vm.courseValues.additionalTeachingMaterialIds.length ? _c('v-card', {
    staticClass: "mb-5",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    attrs: {
      "data-cy": "selectedTeachingMaterialsHeading"
    }
  }, [_vm._v("Valgte læremidler")]), _c('v-card-text', _vm._l(_vm.courseValues.additionalTeachingMaterialIds, function (teachingMaterialId, index) {
    return _c('v-chip', {
      key: index,
      staticClass: "mr-3",
      attrs: {
        "label": "",
        "outlined": "",
        "close": "",
        "data-cy": "selectedTeachingMaterial"
      },
      on: {
        "click:close": function clickClose($event) {
          return _vm.removeTeachingMaterial(teachingMaterialId);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.teachingMaterialName(teachingMaterialId)) + " ")]);
  }), 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }