var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "data-cy": "baseModalForm"
    }
  }, [_c('v-container', {
    class: {
      'pa-0': _vm.noContainer
    }
  }, [_vm.headline ? _c('v-card-title', [_vm._t("headline", function () {
    return [_c('span', {
      staticClass: "headline",
      attrs: {
        "data-cy": "headline"
      }
    }, [_vm._v(_vm._s(_vm.headline))])];
  })], 2) : _vm._e(), _c('v-card-text', [_vm._t("default")], 2), _vm._t("after-card"), _c('v-card-actions', [_c('v-spacer'), _vm._t("actions", function () {
    return [_c('v-btn', {
      attrs: {
        "elevation": "2",
        "primary": ""
      },
      on: {
        "click": _vm.close
      }
    }, [_vm._v("Avbryt")]), !_vm.readonly ? _c('v-btn', {
      attrs: {
        "elevation": "2",
        "color": _vm.submitButtonColor,
        "loading": _vm.loading,
        "data-cy": "modal-submit"
      },
      on: {
        "click": _vm.submitForm
      }
    }, [_vm._v(_vm._s(_vm.submitButtonText))]) : _vm._e()];
  }, {
    "close": _vm.close,
    "submitForm": _vm.submitForm,
    "readonly": _vm.readonly
  })], 2)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }