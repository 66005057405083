
import BaseDatePicker from "@/components/shared/date/BaseDatePicker.vue";
import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { hasMemberOrgAccess } from "@/shared/helpers/accessLevelApiAdapter";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import { EventFormData } from "./schedule.types";

export default defineComponent({
  name: "EventUpsertMenu",
  components: { BaseDatePicker },
  props: {
    event: {
      type: Object as PropType<EventFormData>,
      required: false,
      default: () => ({}),
    },
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    startDate: {
      type: Date,
      required: true,
    },
    endDate: {
      type: Date,
      required: true,
    },
    activator: {
      type: EventTarget,
      required: false,
    },
  },
  emits: ["upsert", "delete", "hide"],
  setup(props, { emit, refs }) {
    const isEditing = computed(() => formData.value?.id !== undefined);
    const formData = useAutoDeepCloneProp<EventFormData>(props, "event");

    const onUpsert = () => {
      const isValid = getValidatableRef(refs.eventUpsertMenuForm)?.validate();
      if (!isValid) {
        return false;
      }
      emit("upsert", formData.value);
      emit("hide");
    };

    const onDelete = () => {
      emit("delete", formData.value);
      emit("hide");
    };

    const onCancel = () => {
      emit("hide");
    };

    return {
      formData,
      isEditing,
      onUpsert,
      onDelete,
      onCancel,
      validateNotEmpty,
      hasMemberOrgAccess,
    };
  },
});
