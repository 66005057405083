var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CourseSection', {
    attrs: {
      "title": "Filer"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var isVocationalSchool = _ref.isVocationalSchool;
        return [_c('div', [_c('div', _vm._l(_vm.files, function (file, idx) {
          return _c('v-chip', {
            key: idx,
            staticClass: "ma-2",
            attrs: {
              "close": ""
            },
            on: {
              "click:close": function clickClose($event) {
                return _vm.deleteFile(file.id);
              }
            }
          }, [_vm._v(" " + _vm._s(file.originalFileName) + " ")]);
        }), 1), _c('v-btn', {
          staticStyle: {
            "width": "100%"
          },
          attrs: {
            "text": ""
          },
          on: {
            "click": _vm.openUploadDialog
          }
        }, [_vm._v("Legg ved fil")]), isVocationalSchool ? _c('v-text-field', {
          attrs: {
            "inset": "",
            "label": _vm.groupIdRequired ? 'Del av kursserie (VI) *' : 'Del av kursserie (VI)'
          },
          model: {
            value: _vm.partOfCourseSeries,
            callback: function callback($$v) {
              _vm.partOfCourseSeries = $$v;
            },
            expression: "partOfCourseSeries"
          }
        }) : _vm._e()], 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }