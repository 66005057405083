var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "file-dialog"
  }, [_c('v-card-title', [_vm._v(" Fil opplasting ")]), _c('v-card-text', [_vm.error ? _c('div', _vm._l(_vm.error, function (err, index) {
    return _c('div', {
      key: index,
      staticClass: "error--text"
    }, [_vm._v(" " + _vm._s(err) + " ")]);
  }), 0) : _vm._e(), _c('v-file-input', {
    attrs: {
      "label": "Velg fil",
      "show-size": "",
      "small-chips": "",
      "multiple": "",
      "accept": _vm.fileType,
      "rules": [function (files) {
        return !files || !files.some(function (file) {
          return file.size > 2e6;
        }) || 'Filen må være mindre enn 2mb';
      }]
    },
    model: {
      value: _vm.files,
      callback: function callback($$v) {
        _vm.files = $$v;
      },
      expression: "files"
    }
  })], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "disabled": _vm.loading,
      "text": ""
    },
    on: {
      "click": _vm.onCancel
    }
  }, [_vm._v("Avbryt")]), _c('v-btn', {
    attrs: {
      "disabled": _vm.loading,
      "loading": _vm.loading,
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.onOk
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "dark": ""
    }
  }, [_vm._v("mdi-cloud-upload")]), _vm._v(" Last opp")], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }