var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    class: "".concat(_vm.isXaxisMargin ? 'mx-2' : '', " ").concat(_vm.isYaxisMargin ? 'my-2' : 'my-1', " text-lg grey--text text--darken-1")
  }, [_vm._v(" " + _vm._s(_vm.label) + ": "), _c('span', {
    staticClass: "font-weight-light",
    attrs: {
      "data-cy": "".concat(_vm.dataCy)
    }
  }, [_vm._v(" " + _vm._s(_vm.valueString) + " ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }