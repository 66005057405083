var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isTeacherHoursRequired ? _c('FormElement', {
    class: {
      required: _vm.isTeacherHoursRequired
    },
    attrs: {
      "inputFieldType": "textField",
      "type": "number",
      "label": "Timer m/lærer",
      "dataCy": "hoursWithInstructor"
    },
    on: {
      "changeEvent": _vm.emitChanges
    },
    model: {
      value: _vm.courseValues.hoursWithInstructor,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues, "hoursWithInstructor", $$v);
      },
      expression: "courseValues.hoursWithInstructor"
    }
  }) : _vm._e(), _c('FormElement', {
    attrs: {
      "inputFieldType": "textField",
      "type": "number",
      "label": _vm.executionPlan.info.hoursWithoutInstructorRequired ? 'Timer u/lærer *' : 'Timer u/lærer',
      "rules": _vm.executionPlan.info.hoursWithoutInstructorRequired ? _vm.validateCourseHourInput({
        minVal: 0
      }) : [],
      "dataCy": "hoursWithoutInstructor"
    },
    on: {
      "changeEvent": _vm.emitChanges
    },
    model: {
      value: _vm.courseValues.hoursWithoutInstructor,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues, "hoursWithoutInstructor", $$v);
      },
      expression: "courseValues.hoursWithoutInstructor"
    }
  }), _vm.teachingMethod !== _vm.TeachingMethods.Klasserom ? _c('FormElement', {
    attrs: {
      "inputFieldType": "textField",
      "type": "number",
      "label": "Timer m/digital undervisning",
      "dataCy": "hoursWithoutInstructor"
    },
    on: {
      "changeEvent": _vm.emitChanges
    },
    model: {
      value: _vm.courseValues.hoursOfDigitalEducation,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues, "hoursOfDigitalEducation", $$v);
      },
      expression: "courseValues.hoursOfDigitalEducation"
    }
  }) : _vm._e(), _c('FormElement', {
    attrs: {
      "inputFieldType": "textField",
      "type": "number",
      "label": _vm.executionPlan.info.hoursSelfStudyRequired ? 'Timer selvstudie *' : 'Timer selvstudie',
      "rules": _vm.executionPlan.info.hoursSelfStudyRequired ? _vm.validateCourseHourInput({
        minVal: 0
      }) : [],
      "dataCy": "hoursSelfStudy"
    },
    on: {
      "changeEvent": _vm.emitChanges
    },
    model: {
      value: _vm.courseValues.hoursSelfStudy,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues, "hoursSelfStudy", $$v);
      },
      expression: "courseValues.hoursSelfStudy"
    }
  }), _vm.curriculum.teachingMethod.toLowerCase() == _vm.TeachingMethods.Nettkurs || _vm.curriculum.teachingMethod.toLowerCase() === _vm.TeachingMethods.KombinertUndervisning ? _c('FormElement', {
    attrs: {
      "inputFieldType": "textField",
      "type": "number",
      "label": "Timer nettkurs *",
      "rules": _vm.validateCourseHourInput({
        minVal: 0
      }),
      "dataCy": "hoursOnline"
    },
    on: {
      "changeEvent": _vm.emitChanges
    },
    model: {
      value: _vm.courseValues.hoursOnline,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues, "hoursOnline", $$v);
      },
      expression: "courseValues.hoursOnline"
    }
  }) : _vm._e(), _c('FormElement', {
    attrs: {
      "value": _vm.totalStudyLoad,
      "inputFieldType": "textField",
      "type": "number",
      "label": "Total studiebelastning * ",
      "readonly": true,
      "dataCy": "totalStudyLoad"
    },
    on: {
      "changeEvent": _vm.emitChanges
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }