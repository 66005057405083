
import { api } from "@/api/api";
import {
  ApiGetCurrentEmployeeDto,
  ApiGetDepartmentDto,
  ApiGetHierarchicalMemberOrganizationDto,
  ApiGetMemberOrganizationFeatureSettingDto,
  ApiIdNameDtoType,
} from "@/api/generated/Api";
import TheMemberOrganizaionModalTreeHierarchy from "@/components/administration/member-organizations/TheMemberOrganizaionModalTreeHierarchy.vue";
import FormElement from "@/components/shared/form/FormElement.vue";
import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { CourseCreationType } from "@/shared/enums/courseCreationType.enum";
import { localeSortByProperty } from "@/shared/helpers/arrayHelpers";
import { isVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { validateBoolean, validateNotEmpty, validateOrganization } from "@/shared/helpers/validationHelpers";
import { useStore } from "@/shared/useHelpers";
import { CourseDto } from "@/store/modules/courses/interfaces/Course.interface";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, PropType, ref, Ref } from "@vue/composition-api";

export default defineComponent({
  name: "CourseInfo",
  components: { FormElement, TheMemberOrganizaionModalTreeHierarchy },
  props: {
    value: {
      type: Object as PropType<CourseDto>,
      required: true,
    },
    courseMode: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore<StoreState>();
    const courseValues: Ref<CourseDto> = useAutoDeepCloneProp<CourseDto>(props, "value");
    const curriculum = computed(() => store.state.courses.curriculum);
    const organizerOrganizations = ref<ApiIdNameDtoType[]>();
    const organizationDepartments = ref<ApiGetDepartmentDto[]>();
    const activeUser = ref<ApiGetCurrentEmployeeDto>();
    const subOrganizerName = ref<string>("");
    const activeFeatureSettings = ref<ApiGetMemberOrganizationFeatureSettingDto[]>([]);
    const hasMultiLevel = ref<boolean>(false);
    const memberOrgHierarchi = ref<ApiGetHierarchicalMemberOrganizationDto[]>([]);

    // Get active feature settings
    const getActiveFeatureSettings = async () => {
      const response = await api.organization.getActivatedMemberOrganizationFeatureSettings();
      activeFeatureSettings.value = response.data;
    };

    const setMemberOrg = (item: ApiGetHierarchicalMemberOrganizationDto) => {
      courseValues.value.subOrganizerOrganizationId = item.id;
      subOrganizerName.value = item.name || "";
      emitChanges();
    };

    const setInitMemberOrg = async (id: number) => {
      if (id) {
        const chosenMemberOrg = (await api.organization.getMemberOrganizationAsync(id)).data;

        if (chosenMemberOrg) {
          setMemberOrg(chosenMemberOrg);
        }
      }
    };

    // check if chosen memberorg has multilevel
    const checkFeatureSettings = (id: number) => {
      if (!id) {
        hasMultiLevel.value = false;
        subOrganizerName.value = "";
        return;
      }

      const memberOrganization = activeFeatureSettings.value.find((x) => x.organizationId === id);

      if (!memberOrganization) {
        hasMultiLevel.value = false;
        subOrganizerName.value = "";
        courseValues.value.subOrganizerOrganizationId = null;
        emitChanges();
      }

      if (memberOrganization && memberOrganization.hasMultiLevelMemberOrganization) {
        hasMultiLevel.value = true;
        getMemberOrgHierarchi(memberOrganization.organizationId);
      }
    };

    const getMemberOrgHierarchi = async (id: number) => {
      memberOrgHierarchi.value = [(await api.organization.getHierarchyOfOrganization(id)).data];
    };

    const getOrganizerOrganizations = async () =>
      (await api.organization.getPotentialOrganizerOrganizations({ isOrganizer: true })).data?.sort(
        localeSortByProperty("name")
      );

    const getOrganizationDepartmens = async () => (await api.organization.getDepartmentsAsync()).data;
    const getUser = async () => (await api.user.getCurrentEmployeeAsync()).data;

    onMounted(async () => {
      const [organizations, departments, user] = await Promise.all([
        getOrganizerOrganizations(),
        getOrganizationDepartmens(),
        getUser(),
        getActiveFeatureSettings(),
      ]);
      organizerOrganizations.value = organizations.filter((org) => org.isActive);
      organizationDepartments.value = departments.filter((org) => org.isActive);
      activeUser.value = user;
      emitChanges();
      checkFeatureSettings(courseValues.value.organizerOrganizationId);
      setInitMemberOrg(courseValues.value.subOrganizerOrganizationId || 0);
    });

    const courseDefaultOptions = computed(() => store.state.courses.courseDefaultOptions);
    const courseDefaultStatuses = computed(() =>
      courseValues.value.status !== "avsluttet" ? courseDefaultOptions.value.courseStatuses : ["avsluttet"]
    );

    const emitChanges = () => {
      emit("input", deepCloneObject(courseValues.value));
    };

    const emitAndCheckFeatureSettings = () => {
      emitChanges();
      checkFeatureSettings(courseValues.value.organizerOrganizationId);
    };

    return {
      setMemberOrg,
      courseValues,
      curriculum,
      subOrganizerName,
      validateNotEmpty,
      validateOrganization,
      activeUser,
      validateBoolean,
      isCourseEdit: props.courseMode === CourseCreationType.Edit,
      isVocationalSchool: computed(() => isVocationalSchool(store.state.courses.curriculum.mainCourseId)),
      courseDefaultOptions,
      courseDefaultStatuses,
      executionPlan: computed(() => store.state.execution.executionPlan),
      emitChanges,
      organizerOrganizations,
      hasMultiLevel,
      memberOrgHierarchi,
      emitAndCheckFeatureSettings,
    };
  },
});
