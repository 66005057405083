var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('BaseDatePicker', {
    attrs: {
      "value": _vm.courseValues.startDate,
      "label": "Startdato *",
      "rules": _vm.validateNotEmpty,
      "dataCy": "courseStartDate"
    },
    on: {
      "input": _vm.setStartDate
    }
  }), _c('BaseDatePicker', {
    attrs: {
      "value": _vm.courseValues.endDate,
      "label": "Sluttdato *",
      "rules": _vm.validateNotEmpty,
      "min": _vm.courseValues.startDate,
      "forceEndOfDate": true,
      "dataCy": "courseEndDate"
    },
    on: {
      "input": _vm.setEndDate
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }