var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    attrs: {
      "offset-y": "",
      "transition": "slide-x-transition",
      "close-on-content-click": false
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({}, 'v-btn', attrs, false), on), [_vm._v(_vm._s(_vm.buttonLabel))])];
      }
    }])
  }, [_c('v-list', {
    staticClass: "custom-menu"
  }, _vm._l(_vm.memberOrgHierarchi, function (item, index) {
    return _c('v-list-item', {
      key: index,
      staticClass: "content-item"
    }, [_c('v-list-item-content', [item && item.children && item.children.length ? _c('v-menu', {
      attrs: {
        "offset-y": "",
        "close-on-content-click": false
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref2) {
          var on = _ref2.on,
              attrs = _ref2.attrs;
          return [_c('v-list-item-title', {
            on: {
              "click": function click($event) {
                return _vm.update(item);
              }
            }
          }, [_c('strong', [_vm._v(" " + _vm._s(item.name) + " ")]), _c('v-icon', _vm._g(_vm._b({
            staticClass: "chevron"
          }, 'v-icon', attrs, false), on), [_vm._v("mdi-chevron-right")])], 1)];
        }
      }], null, true)
    }, [_c('v-list', _vm._l(item.children, function (child, subIndex) {
      return _c('v-list-item', {
        key: subIndex,
        staticClass: "content-item"
      }, [_c('v-list-item-content', [child && child.children && child.children.length ? _c('v-menu', {
        attrs: {
          "offset-y": "",
          "close-on-content-click": false
        },
        scopedSlots: _vm._u([{
          key: "activator",
          fn: function fn(_ref3) {
            var on = _ref3.on,
                attrs = _ref3.attrs;
            return [_c('v-list-item-title', {
              on: {
                "click": function click($event) {
                  return _vm.update(child);
                }
              }
            }, [_c('strong', [_vm._v(" " + _vm._s(child.name) + " ")]), _c('v-icon', _vm._g(_vm._b({
              staticClass: "chevron"
            }, 'v-icon', attrs, false), on), [_vm._v("mdi-chevron-right")])], 1)];
          }
        }], null, true)
      }, [_c('v-list', _vm._l(child.children, function (grandChild, subIndex) {
        return _c('v-list-item', {
          key: subIndex,
          staticClass: "content-item"
        }, [_c('v-list-item-title', {
          on: {
            "click": function click($event) {
              return _vm.update(grandChild);
            }
          }
        }, [_vm._v(_vm._s(grandChild.name) + " ")])], 1);
      }), 1)], 1) : _c('v-list-item-title', {
        on: {
          "click": function click($event) {
            return _vm.update(child);
          }
        }
      }, [_vm._v(" " + _vm._s(child.name) + " ")])], 1)], 1);
    }), 1)], 1) : _c('v-list-item-title', [_c('v-list-item-title', {
      on: {
        "click": function click($event) {
          return _vm.update(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1)], 1)], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }