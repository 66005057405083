var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.headline,
      "modalType": _vm.displayWebInfoModalType
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_vm._l(_vm.generalCourseInfo, function (infoItem) {
    return _c('v-col', {
      key: infoItem.label,
      staticClass: "pa-0",
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('BaseSheetField', {
      attrs: {
        "isXaxisMargin": false,
        "value": infoItem.value,
        "label": infoItem.label
      }
    })], 1);
  }), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "12"
    }
  }, [_vm.courseContactInfo ? _c('p', {
    staticClass: "subtitle-1 mb-1 subtitle-1"
  }, [_vm._v("Kontaktperson")]) : _vm._e()]), _vm._l(_vm.courseContactInfo, function (infoItem) {
    return _c('v-col', {
      key: infoItem.label,
      staticClass: "pa-0",
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('BaseSheetField', {
      attrs: {
        "isXaxisMargin": false,
        "value": infoItem.value,
        "label": infoItem.label
      }
    })], 1);
  }), _vm.courseSupervisorInfo && !_vm.isMainCourseVocationalSchool ? _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "mb-1 subtitle-1"
  }, [_vm._v(" " + _vm._s(_vm.isMainCourseVocationalSchool ? "Studieansvarlig" : "Kursansvarlig") + " ")])]) : _vm._e(), _vm._l(_vm.courseSupervisorInfo, function (infoItem, index) {
    return _c('v-col', {
      key: index,
      staticClass: "pa-0",
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('BaseSheetField', {
      attrs: {
        "isXaxisMargin": false,
        "value": infoItem.value,
        "label": infoItem.label
      }
    })], 1);
  })], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }