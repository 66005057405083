var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "my-4"
  }, [_c('v-alert', {
    attrs: {
      "border": "left",
      "text": "",
      "dense": "",
      "icon": "mdi-calendar-clock"
    }
  }, [_vm._v(_vm._s(_vm.scheduleHours) + " timer m/lærer fordelt. ")]), _vm.validationState.hasValidHoursWithInstructor ? _c('v-alert', {
    attrs: {
      "border": "left",
      "text": "",
      "dense": "",
      "color": "success",
      "icon": "mdi-clock-check-outline"
    }
  }, [_vm._v(_vm._s(_vm.getRoundedHours(_vm.hoursWithInstructor - _vm.scheduleHours, false)) + " av " + _vm._s(_vm.getRoundedHours(_vm.hoursWithInstructor)) + " m/lærer gjenstående.")]) : _vm._e(), !_vm.validationState.hasValidHoursWithInstructor ? _c('v-alert', {
    attrs: {
      "border": "left",
      "text": "",
      "dense": "",
      "color": "error",
      "icon": "mdi-clock-alert-outline"
    }
  }, [_vm._v("Maksimalt " + _vm._s(_vm.getRoundedHours(_vm.hoursWithInstructor)) + " m/lærer kan fordeles - " + _vm._s(_vm.getRoundedHours(_vm.scheduleHours - _vm.hoursWithInstructor)) + " for mye.")]) : _vm._e(), _vm.hoursMax !== undefined && !_vm.validationState.hasValidHoursMax ? _c('v-alert', {
    attrs: {
      "border": "left",
      "text": "",
      "dense": "",
      "color": "error",
      "icon": "mdi-clock-alert-outline"
    }
  }, [_vm._v(" Maksimalt " + _vm._s(_vm.getRoundedHours(_vm.hoursMax)) + " kan fordeles. ")]) : _vm._e(), !_vm.validationState.hasNoConflicts ? _c('v-alert', {
    attrs: {
      "border": "left",
      "text": "",
      "dense": "",
      "color": "error",
      "icon": "mdi-clock-alert-outline"
    }
  }, [_vm._v("Timeplanen har oppføringer som er i konflikt med skolerute.")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }