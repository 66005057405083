
import { defineComponent, ref } from "@vue/composition-api";

interface DraggableElement {
  elStartX: number;
  elStartY: number;
  oldTransition: string;
  mouseStartX: number;
  mouseStartY: number;
  el: any;
}

export default defineComponent({
  name: "BaseModal",
  props: {
    maxWidth: {
      type: Number,
      required: false,
      default: 800,
    },
    width: {
      type: Number,
      required: false,
      default: 800,
    },
  },
  setup(props) {
    const maxWidthFormatted = ref(`${props.maxWidth ?? 800}px`);

    const draggableClasses = ["v-card__title", "v-toolbar__content"];

    (() => {
      const d: DraggableElement = {
        el: undefined,
        elStartX: 0,
        elStartY: 0,
        oldTransition: "",
        mouseStartX: 0,
        mouseStartY: 0,
      };

      document.addEventListener("mousedown", (e: any) => {
        const closestDialog = e.target.closest("div").closest(".v-dialog.v-dialog--active");

        if (e.button === 0 && closestDialog !== null && draggableClasses.includes(e.target.className)) {
          d.el = closestDialog;
          d.mouseStartX = e.clientX;
          d.mouseStartY = e.clientY;
          d.elStartX = d.el.getBoundingClientRect().left;
          d.elStartY = d.el.getBoundingClientRect().top;
          d.el.style.position = "fixed";
          d.el.style.margin = 0;
          d.oldTransition = d.el.style.transition;
          d.el.style.transition = "none";
        }
      });

      document.addEventListener("mousemove", (e) => {
        if (d.el === undefined) {
          return;
        }
        d.el.style.left = `${Math.min(
          Math.max(d.elStartX + e.clientX - d.mouseStartX, 0),
          window.innerWidth - d.el.getBoundingClientRect().width
        )}px`;

        d.el.style.top = `${Math.min(
          Math.max(d.elStartY + e.clientY - d.mouseStartY, 0),
          window.innerHeight - d.el.getBoundingClientRect().height
        )}px`;
      });

      document.addEventListener("mouseup", () => {
        if (d.el === undefined) {
          return;
        }

        d.el.style.transition = d.oldTransition;
        d.el = undefined;
      });
    })();

    return { maxWidthFormatted };
  },
});
