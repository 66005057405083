var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "px-1"
  }, [_vm.isFormDisplayed ? _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [!_vm.isCourseDone ? _c('v-form', {
    ref: "scheduleForm",
    attrs: {
      "lazy-validation": "",
      "disabled": _vm.isFormDisabled
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.emitState.apply(null, arguments);
      }
    }
  }, [_vm.isFormDisabled ? _c('v-card', {
    staticClass: "mb-4",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("mdi-calendar-lock")]), _vm._v(" Skjema låst for endringer")], 1), _c('v-card-text', [_vm.isEditing ? _c('div', [_c('p', [_vm._v("Den eksisterende timeplanen for dette kurset kan redigeres i kalenderen til høyre.")]), _c('p', [_vm._v(" En ny timeplan kan opprettes ved å velge skolerute og fylle ut skjemaet nedenfor. Opprettelse av ny timeplan vil overskrive hele den eksisterende timeplanen. ")])]) : _c('div', [_c('p', [_vm._v(" Det er utført manuelle endringer i timeplanen. Endringer i skjemaet nedenfor vil overskrive alle manuelle endringer som er utført. ")])])]), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "block": "",
      "elevation": "0"
    },
    on: {
      "click": function click($event) {
        _vm.isFormDisabled = false;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("mdi-lock-open-alert")]), _vm._v(" Jeg forstår, lås opp skjemaet")], 1)], 1)], 1) : _vm._e(), _c('v-autocomplete', {
    attrs: {
      "label": "Skolerute",
      "items": _vm.schoolRoutes,
      "item-text": "name",
      "item-value": "id",
      "data-cy": "schoolRouteIdAutocomplete",
      "messages": !_vm.schoolRoute ? ['Velg en skolerute fra listen for å opprette en ny timeplan.'] : [],
      "clearable": ""
    },
    model: {
      value: _vm.schoolRouteId,
      callback: function callback($$v) {
        _vm.schoolRouteId = $$v;
      },
      expression: "schoolRouteId"
    }
  }), _vm.schoolRoute ? _c('div', [_vm.isEditing && !_vm.isFormDisabled ? _c('v-alert', {
    staticClass: "mb-4",
    attrs: {
      "type": "warning",
      "color": "warning",
      "icon": "mdi-calendar-alert",
      "border": "left",
      "text": "",
      "dismissible": ""
    }
  }, [_vm._v(" Endringer i dette skjemaet vil overskrive alle eksisterende kalenderoppføringer for dette kurset. ")]) : _vm._e(), _c('v-expansion-panels', {
    attrs: {
      "mandatory": ""
    },
    model: {
      value: _vm.expandedIntervals,
      callback: function callback($$v) {
        _vm.expandedIntervals = $$v;
      },
      expression: "expandedIntervals"
    }
  }, _vm._l(_vm.formData, function (interval, index) {
    return _c('v-expansion-panel', {
      key: index
    }, [_c('v-expansion-panel-header', [_vm._v(" " + _vm._s(interval.name || "(mangler tittel)") + " ")]), _c('v-expansion-panel-content', [_c('v-text-field', {
      staticClass: "required",
      attrs: {
        "label": "Tittel",
        "rules": _vm.validateNotEmpty,
        "dataCy": "scheduleModalDescription"
      },
      model: {
        value: interval.name,
        callback: function callback($$v) {
          _vm.$set(interval, "name", $$v);
        },
        expression: "interval.name"
      }
    }), _c('div', {
      staticClass: "d-flex align-baseline required"
    }, [_c('BaseDatePicker', {
      attrs: {
        "label": "Fra dato",
        "rules": _vm.validateNotEmpty,
        "min": _vm.startDate.toISOString(),
        "max": _vm.endDate.toISOString(),
        "dataCy": "scheduleModalFromDate"
      },
      model: {
        value: interval.fromDate,
        callback: function callback($$v) {
          _vm.$set(interval, "fromDate", $$v);
        },
        expression: "interval.fromDate"
      }
    }), _c('BaseDatePicker', {
      attrs: {
        "label": "Til dato",
        "rules": _vm.validateNotEmpty,
        "min": interval.fromDate,
        "max": _vm.endDate.toISOString(),
        "forceEndOfDate": true,
        "dataCy": "scheduleModalToDate"
      },
      model: {
        value: interval.toDate,
        callback: function callback($$v) {
          _vm.$set(interval, "toDate", $$v);
        },
        expression: "interval.toDate"
      }
    })], 1), _c('v-btn-toggle', {
      staticClass: "my-4 d-flex",
      attrs: {
        "multiple": "",
        "dense": "",
        "color": "primary"
      },
      model: {
        value: interval.daysOfWeek,
        callback: function callback($$v) {
          _vm.$set(interval, "daysOfWeek", $$v);
        },
        expression: "interval.daysOfWeek"
      }
    }, _vm._l(_vm.allDays, function (day) {
      return _c('v-btn', {
        key: day.value,
        staticClass: "flex-grow-1",
        attrs: {
          "value": day.value,
          "disabled": _vm.isFormDisabled
        }
      }, [_vm._v(" " + _vm._s(day.text.substr(0, 3)) + " ")]);
    }), 1), _c('div', {
      staticClass: "d-flex align-baseline"
    }, [_c('v-text-field', {
      staticClass: "flex-grow-1 mr-2 required",
      attrs: {
        "type": "time",
        "label": "Fra kl.:",
        "rules": _vm.validateNotEmpty
      },
      model: {
        value: interval.startTimeOfDay,
        callback: function callback($$v) {
          _vm.$set(interval, "startTimeOfDay", $$v);
        },
        expression: "interval.startTimeOfDay"
      }
    }), _c('v-text-field', {
      staticClass: "flex-grow-1 required",
      attrs: {
        "type": "time",
        "label": "Til kl.:",
        "rules": _vm.validateNotEmpty,
        "dataCy": "scheduleModalEndsAt"
      },
      model: {
        value: interval.endTimeOfDay,
        callback: function callback($$v) {
          _vm.$set(interval, "endTimeOfDay", $$v);
        },
        expression: "interval.endTimeOfDay"
      }
    })], 1), _c('div', {
      staticClass: "d-flex align-baseline"
    }, [_c('v-text-field', {
      staticClass: "mr-2",
      attrs: {
        "prefix": "Gjenta hver",
        "suffix": ".",
        "type": "number",
        "min": "1",
        "hide-details": "",
        "single-line": ""
      },
      model: {
        value: interval.recurring,
        callback: function callback($$v) {
          _vm.$set(interval, "recurring", $$v);
        },
        expression: "interval.recurring"
      }
    }), _c('v-select', {
      attrs: {
        "items": [{
          text: 'uke',
          value: 'week'
        }, {
          text: 'måned',
          value: 'month'
        }]
      },
      model: {
        value: interval.recurringInterval,
        callback: function callback($$v) {
          _vm.$set(interval, "recurringInterval", $$v);
        },
        expression: "interval.recurringInterval"
      }
    })], 1), index > 0 ? _c('div', [_c('v-btn', {
      attrs: {
        "color": "error",
        "outlined": ""
      },
      on: {
        "click": function click($event) {
          return _vm.removeFormDataInterval(index);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-delete")]), _vm._v("Fjern")], 1)], 1) : _vm._e()], 1)], 1);
  }), 1), !_vm.isFormDisabled ? _c('div', {
    staticClass: "mt-4"
  }, [_c('v-btn', {
    attrs: {
      "block": "",
      "color": "blue lighten-1",
      "outlined": ""
    },
    on: {
      "click": _vm.addFormDataInterval
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("mdi-calendar-plus")]), _vm._v(" Nytt intervall")], 1)], 1) : _vm._e()], 1) : _vm._e()], 1) : _vm._e()], 1) : _vm._e(), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "md": _vm.isFormDisplayed ? 6 : 12
    }
  }, [_c('ScheduleCalendar', {
    attrs: {
      "events": _vm.calendarEvents,
      "startDate": _vm.startDate,
      "endDate": _vm.endDate,
      "calendarHeight": _vm.calendarHeight
    },
    on: {
      "clickEvent": _vm.handleClickEvent
    }
  }), _c('EventUpsertMenu', _vm._b({
    attrs: {
      "startDate": _vm.startDate,
      "endDate": _vm.endDate,
      "activator": _vm.eventMenuActivator
    },
    on: {
      "upsert": _vm.handleUpsertEvent,
      "delete": _vm.handleDeleteEvent,
      "hide": function hide($event) {
        _vm.eventMenuData.visible = false;
      }
    }
  }, 'EventUpsertMenu', _vm.eventMenuData, false)), _vm.isFormDisplayed ? _c('ScheduleBanners', {
    attrs: {
      "schedules": _vm.schedule,
      "hoursMax": _vm.hoursMax,
      "hoursWithInstructor": _vm.hoursWithInstructor,
      "validationState": _vm.validationState
    }
  }) : _vm._e(), _c('v-expansion-panels', {
    staticClass: "my-4",
    attrs: {
      "multiple": ""
    }
  }, [_c('v-expansion-panel', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.scheduleConflictList.length,
      expression: "scheduleConflictList.length"
    }]
  }, [_c('v-expansion-panel-header', {
    staticClass: "white--text",
    attrs: {
      "color": "error"
    }
  }, [_c('span', [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "white"
    }
  }, [_vm._v("mdi-calendar-alert")]), _vm._v(_vm._s(_vm.scheduleConflictList.length) + " " + _vm._s(_vm.scheduleConflictList.length === 1 ? "oppføring" : "oppføringer") + " (totalt " + _vm._s(_vm.getIntervalHoursLabel(_vm.scheduleConflictInterval)) + ") i konflikt med skolerute")], 1)]), _c('v-expansion-panel-content', [_c('ScheduleList', {
    attrs: {
      "items": _vm.scheduleConflictList,
      "conflicts": _vm.scheduleConflictMap,
      "isCourseDone": _vm.isCourseDone
    },
    on: {
      "clickEdit": _vm.handleClickEvent
    }
  })], 1)], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('span', [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("mdi-calendar-month")]), _vm._v("Alle kalenderoppføringer")], 1)]), _c('v-expansion-panel-content', [_c('ScheduleList', {
    attrs: {
      "items": _vm.scheduleList,
      "conflicts": _vm.scheduleConflictMap,
      "isCourseDone": _vm.isCourseDone
    },
    on: {
      "clickEdit": _vm.handleClickEvent
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }