var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('BaseDatePicker', {
    attrs: {
      "label": "".concat(_vm.isVocationalSchool ? 'Søknadsfrist' : 'Påmeldingsfrist').concat(_vm.executionPlan.info.enrollmentDeadlineRequired ? '*' : ''),
      "rules": _vm.executionPlan.info.enrollmentDeadlineRequired ? _vm.validateNotEmpty : [],
      "dataCy": "enrollmentDeadline"
    },
    on: {
      "input": _vm.emitChanges
    },
    model: {
      value: _vm.courseValues.enrollmentDeadline,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues, "enrollmentDeadline", $$v);
      },
      expression: "courseValues.enrollmentDeadline"
    }
  }), _c('BaseDatePicker', {
    attrs: {
      "label": _vm.executionPlan.info.unenrollmentDeadlineRequired ? 'Avmeldingsfrist *' : 'Avmeldingsfrist',
      "rules": _vm.executionPlan.info.unenrollmentDeadlineRequired ? _vm.validateNotEmpty : [],
      "max": _vm.courseValues.endDate,
      "dataCy": "unEnrollmentDeadline"
    },
    on: {
      "input": _vm.emitChanges
    },
    model: {
      value: _vm.courseValues.unenrollmentDeadline,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues, "unenrollmentDeadline", $$v);
      },
      expression: "courseValues.unenrollmentDeadline"
    }
  }), _c('FormElement', {
    attrs: {
      "inputFieldType": "textField",
      "type": "number",
      "label": "Min. ".concat(_vm.isVocationalSchool ? 'studenter' : 'deltakere').concat(_vm.executionPlan.info.minimumParticipantsRequired ? ' *' : ''),
      "rules": _vm.executionPlan.info.minimumParticipantsRequired ? _vm.validateNotEmpty : [],
      "dataCy": "minimumParticipants"
    },
    on: {
      "changeEvent": _vm.emitChanges
    },
    model: {
      value: _vm.courseValues.minimumParticipants,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues, "minimumParticipants", $$v);
      },
      expression: "courseValues.minimumParticipants"
    }
  }), _c('FormElement', {
    attrs: {
      "inputFieldType": "textField",
      "type": "number",
      "label": "Maks. ".concat(_vm.isVocationalSchool ? 'studenter' : 'deltakere').concat(_vm.executionPlan.info.maximumParticipantsRequired ? ' *' : ''),
      "rules": _vm.executionPlan.info.maximumParticipantsRequired ? _vm.validateNotEmpty : [],
      "dataCy": "maxParticipants"
    },
    on: {
      "changeEvent": _vm.emitChanges
    },
    model: {
      value: _vm.courseValues.maxParticipants,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues, "maxParticipants", $$v);
      },
      expression: "courseValues.maxParticipants"
    }
  }), _vm.isVocationalSchool ? _c('FormElement', {
    attrs: {
      "inputFieldType": "textField",
      "type": "number",
      "label": "Antall studieplasser *",
      "rules": _vm.validateNotEmpty,
      "dataCy": "participantCapacity"
    },
    on: {
      "changeEvent": _vm.emitChanges
    },
    model: {
      value: _vm.courseValues.participantCapacity,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues, "participantCapacity", $$v);
      },
      expression: "courseValues.participantCapacity"
    }
  }) : _vm._e(), _c('FormElement', {
    attrs: {
      "inputFieldType": "autocomplete",
      "label": _vm.executionPlan.info.financiersRequired ? 'Finansiert av *' : 'Finansiert av',
      "inputItems": _vm.customerOrganizations,
      "multiple": "",
      "itemText": "name",
      "itemValue": "id",
      "dataCy": "financedByOrganizationIds"
    },
    on: {
      "changeEvent": _vm.emitChanges
    },
    model: {
      value: _vm.courseValues.financedByOrganizationIds,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues, "financedByOrganizationIds", $$v);
      },
      expression: "courseValues.financedByOrganizationIds"
    }
  }), _c('v-switch', {
    attrs: {
      "input-value": !_vm.courseValues.isOrderable,
      "inset": "",
      "label": "".concat(_vm.courseValues.isOrderable ? 'Ikke web' : 'Til web'),
      "rules": _vm.validateBoolean,
      "data-cy": "isOrderableSwitch"
    },
    on: {
      "change": _vm.updateIsOrderable
    }
  }), _c('v-switch', {
    attrs: {
      "input-value": _vm.courseValues.isCommissionedCourse,
      "inset": "",
      "label": "".concat(_vm.courseValues.isCommissionedCourse ? 'Bestillingskurs' : 'Åpent kurs'),
      "rules": _vm.validateBoolean,
      "data-cy": "commisionCourseSwitch"
    },
    on: {
      "change": _vm.toggleIsCommissionedCourse
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }