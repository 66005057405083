import router from "@/router/router";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { DashboardRouteNames } from "./../enums/RouteNames/dashboardRouteNames.enum";
import { CurriculumRouteNames } from "@/shared/enums/RouteNames/curriculumRouteNamesEnum";
import { CourseRouteNames } from "@/shared/enums/RouteNames/courseRouteNames.enum";
import { AdminRouteNames } from "@/shared/enums/RouteNames/adminRouteNamesEnum";

export const useNavigateBack = (
  fallbackRoute:
    | AdminRouteNames
    | CourseRouteNames
    | CurriculumRouteNames
    | DashboardRouteNames
    | SingleCourseRouteNames,
  params?: any,
  query?: any
) => {
  if (history.length < 1) {
    router.push({ name: fallbackRoute, params, query });
    return;
  }
  history.back();
};
