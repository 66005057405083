var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.initDone ? _c('BaseStepperDynamic', {
    staticClass: "mt-3",
    attrs: {
      "selectedSteps": _vm.selectedSteps,
      "isPost": true,
      "postBtn": _vm.isCourseEdit ? 'Oppdater kurs' : 'Opprett',
      "disablePostButton": _vm.isCourseDone || _vm.hasSubmitted,
      "loading": _vm.loading
    },
    on: {
      "cancel": _vm.cancel,
      "post": _vm.createOrEditStudyplan,
      "nextStep": _vm.nextStep
    },
    scopedSlots: _vm._u([_vm._l(_vm.selectedSteps, function (step, idx) {
      return {
        key: "step".concat(step.id),
        fn: function fn() {
          return [_c(step.component, {
            key: idx,
            ref: "step".concat(step.id),
            refInFor: true,
            tag: "component",
            attrs: {
              "courseMode": _vm.courseMode,
              "courseId": _vm.courseId,
              "curriculumId": _vm.curriculumId,
              "curriculum": _vm.curriculum
            },
            on: {
              "input": _vm.courseValuesChanged
            },
            model: {
              value: _vm.courseValues,
              callback: function callback($$v) {
                _vm.courseValues = $$v;
              },
              expression: "courseValues"
            }
          })];
        },
        proxy: true
      };
    })], null, true)
  }) : _vm._e(), _c('v-dialog', {
    attrs: {
      "max-width": "400",
      "persistent": ""
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) { return null; }
        return _vm.cancelSubmitModal.apply(null, arguments);
      }
    },
    model: {
      value: _vm.displaySubmitModal,
      callback: function callback($$v) {
        _vm.displaySubmitModal = $$v;
      },
      expression: "displaySubmitModal"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline",
    attrs: {
      "data-cy": "modalTitleConfirm"
    }
  }, [_vm._v(" " + _vm._s(_vm.modalTitle))]), _c('v-card-text'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "disabled": _vm.loading,
      "loading": _vm.loading,
      "text": ""
    },
    on: {
      "click": _vm.cancelSubmitModal
    }
  }, [_vm._v(" Avbryt ")]), _c('v-btn', {
    attrs: {
      "disabled": _vm.loading,
      "loading": _vm.loading,
      "color": "primary",
      "data-cy": "confirmCreateCourse"
    },
    on: {
      "click": _vm.createCourse
    }
  }, [_vm._v(" Bekreft ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }