import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";

/**
 * Filters the array by the provided unique property.
 * If multiple are found, the last one will be selected.
 * @param array The array to filter
 * @param propertySelector The callback function to select the value to be filtered on
 * @returns A new array with deep cloned elements
 */
export function uniqueBy<T>(array: T[], propertySelector: (element: T) => any): T[] {
  const uniquePropElemRec = array.reduce<Record<any, T>>((uniquePropElem, element) => {
    const property = propertySelector(element);
    return property ? { ...uniquePropElem, [property]: deepCloneObject(element) } : uniquePropElem;
  }, {});
  return Object.values(uniquePropElemRec);
}

/**
 * Removes duplicate items from array
 * @param array The array to filter
 * @returns The array with only unique items
 */
export const uniqueArray = <T>(array: T[]): T[] =>
  array.reduce((acc: T[], cur: T) => {
    if (!acc.includes(cur)) {
      acc.push(cur);
    }
    return acc;
  }, []);

export const localeSortByProperty =
  <ObjectType>(key: keyof ObjectType) =>
  (a: ObjectType, b: ObjectType) => {
    const compareA = `${a[key] ?? ""}`.trim();
    const compareB = `${b[key] ?? ""}`.trim();
    return compareA.localeCompare(compareB);
  };
