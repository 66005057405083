
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { defineComponent, PropType } from "@vue/composition-api";
export default defineComponent({
  name: "BaseModalForm",
  props: {
    headline: {
      type: String,
      required: false,
    },
    submitButtonText: {
      type: String,
      default: "Lagre",
    },
    submitButtonColor: {
      type: String,
      default: "primary",
    },
    modalType: {
      type: String as PropType<ModalType>,
      required: true,
    },
    editable: {
      type: Boolean,
      required: false,
    },
    noContainer: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    readonly(): boolean {
      return this.modalType === ModalType.Display;
    },
  },
  methods: {
    submitForm() {
      this.$emit("submit");
    },
    close() {
      this.$emit("close");
    },
  },
});
