var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.$refs.calendar ? _c('v-toolbar', {
    attrs: {
      "flat": ""
    }
  }, [!_vm.hasMemberOrgAccess ? _c('BaseTooltipIconButton', {
    attrs: {
      "color": "primary",
      "btnText": "Ny oppføring",
      "icon": "mdi-plus"
    },
    on: {
      "handleClick": function handleClick($event) {
        return _vm.handleClickEvent({
          event: null,
          nativeEvent: $event
        });
      }
    }
  }) : _vm._e(), _c('v-btn', {
    attrs: {
      "fab": "",
      "text": "",
      "small": "",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.calendar.prev();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" mdi-chevron-left ")])], 1), _c('v-toolbar-title', {
    staticClass: "flex-grow-1 text-center"
  }, [_vm._v(" " + _vm._s(_vm.$refs.calendar.title) + " ")]), _c('v-btn', {
    attrs: {
      "fab": "",
      "text": "",
      "small": "",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.calendar.next();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" mdi-chevron-right ")])], 1), _c('v-btn', {
    staticClass: "ml-4",
    attrs: {
      "outlined": "",
      "text": ""
    },
    on: {
      "click": _vm.setToday
    }
  }, [_vm._v("I dag")])], 1) : _vm._e(), _c('v-sheet', {
    attrs: {
      "height": _vm.calendarHeight
    }
  }, [_c('v-calendar', {
    ref: "calendar",
    attrs: {
      "events": _vm.events,
      "event-color": _vm.getEventColor,
      "start": _vm.startDate,
      "end": _vm.endDate,
      "weekdays": _vm.weekdays,
      "event-more": false,
      "event-ripple": false,
      "color": "primary",
      "locale": "no",
      "type": "month",
      "show-week": ""
    },
    on: {
      "click:event": _vm.handleClickEvent,
      "click:date": _vm.handleClickDate
    },
    model: {
      value: _vm.calendarValue,
      callback: function callback($$v) {
        _vm.calendarValue = $$v;
      },
      expression: "calendarValue"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }