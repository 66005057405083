
import { formatLocalizedDate, formatLocalizedTime } from "@/shared/helpers/dateHelpers";
import { defineComponent, PropType } from "@vue/composition-api";
import { compareAsc } from "date-fns";
import { ScheduleEditEvent, VuetifyCalendarEvent } from "./schedule.types";

export default defineComponent({
  name: "ScheduleList",
  props: {
    items: {
      type: Array as PropType<VuetifyCalendarEvent[]>,
      required: true,
    },
    conflicts: {
      type: Object as PropType<Record<string, VuetifyCalendarEvent>>,
      required: false,
      default: () => ({}),
    },
    isCourseDone: {
      type: Boolean,
      required: false,
    },
  },
  emits: ["clickEdit"],
  setup(props, { emit }) {
    const handleClickEdit = (eventData: ScheduleEditEvent) => {
      emit("clickEdit", eventData);
    };

    return {
      handleClickEdit,
      headers,
      formatLocalizedDate,
      formatLocalizedTime,
    };
  },
});

const headers = [
  {
    text: "Tittel",
    value: "name",
  },
  {
    text: "Dato",
    value: "start",
    sort: (a: Date, b: Date) => compareAsc(a, b),
  },
  {
    text: "Start",
    value: "startTime",
    sortable: false,
  },
  {
    text: "Slutt",
    value: "endTime",
    sortable: false,
  },
  {
    text: "",
    value: "actions",
    sortable: false,
  },
];
