var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "form",
    attrs: {
      "disabled": _vm.isCourseDone
    }
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "section-container"
  }, [_c('FormElement', {
    attrs: {
      "inputFieldType": "textField",
      "label": "Pris",
      "type": "number",
      "suffix": ",-",
      "dataCy": "coursePrice"
    },
    model: {
      value: _vm.courseValues.economy.price,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues.economy, "price", $$v);
      },
      expression: "courseValues.economy.price"
    }
  }), _c('FormElement', {
    attrs: {
      "inputFieldType": "textField",
      "label": "Avbestillingsgebyr",
      "type": "number",
      "suffix": ",-",
      "dataCy": "courseCancellationFee"
    },
    model: {
      value: _vm.courseValues.economy.cancellationFee,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues.economy, "cancellationFee", $$v);
      },
      expression: "courseValues.economy.cancellationFee"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "value": _vm.departmentId,
      "items": _vm.organizationList,
      "item-text": function itemText(item) {
        return item.id + ' - ' + item.name;
      },
      "item-value": "id",
      "label": "Regnskapsklient *",
      "rules": _vm.validateNotEmpty,
      "readonly": ""
    }
  }), _c('v-autocomplete', {
    attrs: {
      "items": _vm.economyDefaultOptions.economyDepartments,
      "label": "Regnskapsavdeling *",
      "item-text": "name",
      "item-value": "id",
      "data-cy": "accountingClientAutocomplete",
      "rules": _vm.validateNotEmpty
    },
    model: {
      value: _vm.courseValues.economy.accountingDepartment,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues.economy, "accountingDepartment", $$v);
      },
      expression: "courseValues.economy.accountingDepartment"
    }
  }), _c('v-select', {
    attrs: {
      "inset": "",
      "items": _vm.economyDefaultOptions.grants,
      "item-text": "name",
      "item-value": "id",
      "label": "Tilskuddskode *",
      "data-cy": "grantIdSelect",
      "rules": _vm.validateNotEmpty
    },
    model: {
      value: _vm.courseValues.economy.grantId,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues.economy, "grantId", $$v);
      },
      expression: "courseValues.economy.grantId"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "items": _vm.availableArticles,
      "label": "Artikler",
      "multiple": "",
      "clearable": "",
      "item-text": function itemText(item) {
        return item.id + ' - ' + item.name;
      },
      "item-value": "id"
    },
    model: {
      value: _vm.courseValues.economy.articleNos,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues.economy, "articleNos", $$v);
      },
      expression: "courseValues.economy.articleNos"
    }
  }), _c('FormElement', {
    attrs: {
      "inputFieldType": "textField",
      "label": "KAS-ID",
      "rules": _vm.courseValues.kasId ? _vm.validateKasId : []
    },
    model: {
      value: _vm.courseValues.kasId,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues, "kasId", $$v);
      },
      expression: "courseValues.kasId"
    }
  }), _c('v-switch', {
    attrs: {
      "inset": "",
      "label": _vm.courseValues.economy.allowExpenses ? 'Tillater reise/diett *' : 'Tillater ikke reise/diett *'
    },
    model: {
      value: _vm.courseValues.economy.allowExpenses,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues.economy, "allowExpenses", $$v);
      },
      expression: "courseValues.economy.allowExpenses"
    }
  })], 1), _vm.$route.params.id && !_vm.isCourseDone ? _c('Files', {
    attrs: {
      "id": _vm.$route.params.id
    }
  }) : _vm.$route.params.id ? _c('div', {
    staticClass: "title font-weight-light text-center",
    staticStyle: {
      "align-self": "center"
    }
  }, [_vm._v(" Du kan laste opp filer når " + _vm._s(_vm.isVocationalSchool ? "utdanningen" : "kurset") + " er opprettet ")]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }