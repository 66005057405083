var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('EmployeeSearchInput', {
    attrs: {
      "label": _vm.isVocationalSchool ? 'Studieansvarlig' : 'Kursansvarlig',
      "cypressId": "courseSupervisorAutocomplete"
    },
    on: {
      "input": _vm.emitChanges
    },
    model: {
      value: _vm.courseValues.courseSupervisorId,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues, "courseSupervisorId", $$v);
      },
      expression: "courseValues.courseSupervisorId"
    }
  }), _c('EmployeeSearchInput', {
    attrs: {
      "label": _vm.isVocationalSchool ? 'Studieadministrator' : 'Kursadministrator',
      "cypressId": "courseLecturerAutocomplete"
    },
    on: {
      "input": _vm.emitChanges
    },
    model: {
      value: _vm.courseValues.lecturerId,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues, "lecturerId", $$v);
      },
      expression: "courseValues.lecturerId"
    }
  }), _c('EmployeeSearchInput', {
    attrs: {
      "label": "Kontaktperson",
      "cypressId": "courseContactAutocomplete"
    },
    on: {
      "input": _vm.emitChanges
    },
    model: {
      value: _vm.courseValues.contactId,
      callback: function callback($$v) {
        _vm.$set(_vm.courseValues, "contactId", $$v);
      },
      expression: "courseValues.contactId"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }