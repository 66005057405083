import { api } from "@/api/api";
import { ApiGetHolidayDto, ApiScheduleBaseDto, ApiScheduleBaseDtoValidationResponseDto } from "@/api/generated/Api";
import { schoolRouteEventColor } from "@/components/course/new/steps/plan/schedule.constants";
import {
  ScheduleEntry,
  ScheduleEntryProps,
  VuetifyCalendarEvent,
} from "@/components/course/new/steps/plan/schedule.types";
import { getIsoDate } from "@/shared/helpers/dateHelpers";
import { generateUuid } from "@/shared/helpers/uuidHelpers";
import { AxiosError } from "axios";
import {
  compareAsc,
  differenceInMinutes,
  eachDayOfInterval,
  eachMonthOfInterval,
  eachWeekOfInterval,
  getDate,
  getDay,
  parseISO,
  set,
  setDay,
} from "date-fns";
import { formatLocalizedDate } from "./dateHelpers";

export const validateSchedules = async (
  courseId: number,
  schedules: ApiScheduleBaseDto[]
): Promise<string[] | true> => {
  try {
    // Validation endpoint returns 200 with an empty response body when
    // there are no validation errors, otherwise it fails with 400 with an
    // validation error DTO
    await api.course.validateSchedulesForCourseAsync(courseId, { schedules });

    // No validation errors
    return true;
  } catch (error) {
    // Validation errors - return an array of formatted error messages
    const response = (error as AxiosError<ApiScheduleBaseDtoValidationResponseDto>).response?.data;
    if (!response) {
      return ["En ukjent valideringsfeil har oppstått"];
    }

    const generalErrorMessage: string = response.generalValidationErrors?.length
      ? `Valideringsfeil: ${response.generalValidationErrors?.join(", ")}`
      : "";

    const errorMessages = (response.validationList || []).flatMap((item): string => {
      if (!item.validatedItem || !item.validationErrors) {
        return "";
      }

      const { start, end } = item.validatedItem;
      const errors = item.validationErrors.join(", ");

      if (start && end) {
        const startDate = formatLocalizedDate(start);
        const endDate = formatLocalizedDate(end);
        const date = startDate === endDate ? startDate : `${startDate}–${endDate}`;

        return `Valideringsfeil for oppføring ${date}${
          item.validatedItem.title ? `("${item.validatedItem.title}")` : ""
        }: ${errors}`;
      }
      return `Valideringsfeil: ${errors}`;
    });

    // Return all non-falsy error message items
    return [generalErrorMessage, ...errorMessages].filter((item) => item);
  }
};

export const createScheduleFromList = (entries: ScheduleEntry[]) => entries.flatMap(createSchedule);

export const createSchedule = ({
  fromDate: fromDateString,
  toDate: toDateString,
  name,
  daysOfWeek,
  recurring,
  recurringInterval,
  startTimeOfDay: startTimeOfDayString,
  endTimeOfDay: endTimeOfDayString,
}: ScheduleEntryProps): VuetifyCalendarEvent[] => {
  const start = parseISO(getIsoDate(fromDateString));
  const end = parseISO(getIsoDate(toDateString));
  const interval = { start, end };
  const intervalDates = eachDayOfInterval(interval);

  const startDayOfWeek = getDay(start);
  const startDayOfMonth = getDate(start);

  if (!isDayOfWeek(startDayOfWeek)) {
    return [];
  }

  const [startHour = 0, startMinute = 0] = startTimeOfDayString.split(":");
  const startTimeOfDay = { hours: +startHour, minutes: +startMinute };

  const [endHour = 0, endMinute = 0] = endTimeOfDayString.split(":");
  const endTimeOfDay = { hours: +endHour, minutes: +endMinute };

  return daysOfWeek
    .flatMap((dayOfWeek) => {
      const potentialDates = intervalDates.filter((date) => getDay(date) === dayOfWeek);
      const intervalStartDates =
        recurringInterval === "month"
          ? getMonthStartDates(interval, recurring, startDayOfMonth)
          : getWeekStartDates(interval, recurring, startDayOfWeek);

      const dates = intervalStartDates
        .map((intervalStartDate) =>
          potentialDates.find((potentialDate) => compareAsc(potentialDate, intervalStartDate) >= 0)
        )
        .filter(
          (potentialDate) =>
            potentialDate !== undefined && compareAsc(potentialDate, start) >= 0 && compareAsc(potentialDate, end) <= 0
        );

      return dates.flatMap((date) => ({
        id: generateUuid(),
        name,
        start: set(date ?? new Date(), startTimeOfDay),
        end: set(date ?? new Date(), endTimeOfDay),
      }));
    })
    .sort((a, b) => compareAsc(a.start, b.start));
};

export const mapStoredSchedule = (schedule: ApiScheduleBaseDto[]): VuetifyCalendarEvent[] =>
  schedule.map(({ title: name, start, end }) => ({
    id: generateUuid(),
    name: name ?? "(mangler navn)",
    start: start ? parseISO(start) : new Date(),
    end: end ? parseISO(end) : new Date(),
  }));

const isDayOfWeek = (value: number): value is 0 | 1 | 2 | 3 | 4 | 5 | 6 => [0, 1, 2, 3, 4, 5, 6].includes(value);

const getWeekStartDates = (interval: Interval, recurring: number, startDayOfWeek: number) =>
  eachWeekOfInterval(interval)
    .filter((_, weekIndex) => weekIndex % recurring === 0)
    .map((date) => setDay(date, startDayOfWeek));

const getMonthStartDates = (interval: Interval, recurring: number, startDayOfMonth: number) =>
  eachMonthOfInterval(interval)
    .filter((_, monthIndex) => monthIndex % recurring === 0)
    .map((date) => set(date, { date: startDayOfMonth }));

export const getRoundedHours = (hours?: number, addSuffix = true) => {
  const suffix = hours === 1 ? " time" : " timer";
  return `${(+(hours ?? 0).toFixed(1)).toLocaleString()}${addSuffix ? suffix : ""}`;
};

export const getIntervalHoursLabel = ({ start, end }: Interval, addSuffix = true) =>
  getRoundedHours(differenceInMinutes(end, start) / 60, addSuffix);

export const getSchoolRouteEvents = (schoolRoute?: ApiGetHolidayDto) =>
  schoolRoute?.details?.map((schoolRoute) => ({
    id: generateUuid(),
    name: schoolRoute.periodName ?? "(mangler navn)",
    start: new Date(schoolRoute.fromDate),
    end: new Date(schoolRoute.toDate),
    color: schoolRouteEventColor,
    timed: false,
  })) ?? [];
