
import { ApiGetTeachingMaterialDto } from "@/api/generated/Api";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { useStore } from "@/shared/useHelpers";
import { CourseDto } from "@/store/modules/courses/interfaces/Course.interface";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  name: "NewCourseTeachingMaterialStep",
  components: { BaseTableFiltered },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const store = useStore<StoreState>();

    const search = ref("");
    const courseValues = useAutoDeepCloneProp<CourseDto>(props, "value");

    const addTeachingMaterial = (item: ApiGetTeachingMaterialDto) => {
      if (!courseValues.value.additionalTeachingMaterialIds) {
        throw new Error("AdditionalTeachingMaterialIds undefined");
      }
      courseValues.value.additionalTeachingMaterialIds.indexOf(item.id) === -1 &&
        courseValues.value.additionalTeachingMaterialIds.push(item.id);
    };
    const removeTeachingMaterial = (itemId: number) => {
      if (!courseValues.value.additionalTeachingMaterialIds) {
        throw new Error("AdditionalTeachingMaterialIds undefined");
      }
      courseValues.value.additionalTeachingMaterialIds.splice(
        courseValues.value.additionalTeachingMaterialIds.findIndex((id: number) => id === itemId),
        1
      );
    };

    const teachingMaterialName = (teachingMaterialId: number) =>
      store.state.teachingMaterials.teachingMaterials.find((item) => item.id === teachingMaterialId)?.name;

    const headers = [
      { text: "Kategori", value: "category" },
      { text: "Handlinger", value: "actions" },
      { text: "Navn", value: "name" },
      {
        text: "Beskrivelse",
        value: "description",
      },
    ];

    const onStepGoBack = () => {
      // need to emit to save for when going back
      context.emit("input", deepCloneObject(courseValues.value));
    };

    const validate = () => {
      context.emit("input", deepCloneObject(courseValues.value));
      return true;
    };

    const curriculumTeachingMaterials = computed(() =>
      store.state.courses.curriculum.course.teachingMaterials.map((teachingMaterial) => teachingMaterial.id)
    );
    const teachingMaterials = computed(() =>
      store.state.teachingMaterials.teachingMaterials.filter(
        (teachingMaterial) => !curriculumTeachingMaterials.value.includes(teachingMaterial.id)
      )
    );

    return {
      headers,
      search,
      courseValues,
      addTeachingMaterial,
      removeTeachingMaterial,
      teachingMaterialName,
      validate,
      onStepGoBack,
      teachingMaterials,
      curriculumTeachingMaterials,
    };
  },
});
