var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_vm._l(_vm.courseValues.tests, function (test, index) {
    return _c('div', {
      key: index
    }, [_c('v-row', [_c('v-col', {
      attrs: {
        "cols": "12",
        "xs": "12",
        "sm": "6",
        "md": "3"
      }
    }, [_c('v-autocomplete', {
      attrs: {
        "label": _vm.isVocationalSchool ? 'Vurderingsform *' : 'Testtype *',
        "items": _vm.testTypes,
        "rules": _vm.validateNotEmpty,
        "item-text": "name",
        "item-value": "id",
        "data-cy": "testType"
      },
      model: {
        value: test.testTypeId,
        callback: function callback($$v) {
          _vm.$set(test, "testTypeId", $$v);
        },
        expression: "test.testTypeId"
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "12",
        "xs": "12",
        "sm": "6",
        "md": "3"
      }
    }, [_c('FormElement', {
      attrs: {
        "inputFieldType": "textField",
        "label": "Navn *",
        "rules": _vm.validateNotEmpty,
        "data-cy": "testName"
      },
      model: {
        value: test.name,
        callback: function callback($$v) {
          _vm.$set(test, "name", $$v);
        },
        expression: "test.name"
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "12",
        "xs": "12",
        "sm": "6",
        "md": "5"
      }
    }, [_c('FormElement', {
      attrs: {
        "inputFieldType": "textArea",
        "label": "Beskrivelse *",
        "rules": _vm.validateNotEmpty,
        "rows": "1",
        "data-cy": "testdescription"
      },
      model: {
        value: test.description,
        callback: function callback($$v) {
          _vm.$set(test, "description", $$v);
        },
        expression: "test.description"
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "12",
        "xs": "12",
        "sm": "6",
        "md": "1"
      }
    }, [index !== 0 ? _c('BaseTooltipIconButton', {
      attrs: {
        "color": "primary",
        "btnText": "Slett ".concat(_vm.isVocationalSchool ? 'vurderingsform' : 'test'),
        "icon": "mdi-delete"
      },
      on: {
        "handleClick": function handleClick($event) {
          return _vm.deleteTestEntry(index);
        }
      }
    }) : _vm._e()], 1)], 1)], 1);
  }), _c('v-row', [_c('v-col', [_c('v-btn', {
    attrs: {
      "color": "primary",
      "data-cy": "addTest"
    },
    on: {
      "click": _vm.addTestEntry
    }
  }, [_vm._v("Legg til " + _vm._s(_vm.isVocationalSchool ? "vurderingsform" : "test"))])], 1)], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }