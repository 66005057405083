
import { api } from "@/api/api";
import { ApiGetHolidayDto, ApiScheduleBaseDto, ApiUpsertPlanDtoType } from "@/api/generated/Api";
import BaseDatePicker from "@/components/shared/date/BaseDatePicker.vue";
import { debounceHelper } from "@/shared/debounce";
import { hasMemberOrgAccess } from "@/shared/helpers/accessLevelApiAdapter";
import { allDays } from "@/shared/helpers/courseHelpers";
import { getIsoDate, getTimeOfDay, setDateHourAndMin } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import {
  createScheduleFromList,
  getIntervalHoursLabel,
  getRoundedHours,
  getSchoolRouteEvents,
  mapStoredSchedule,
} from "@/shared/helpers/scheduleHelpers";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { generateUuid } from "@/shared/helpers/uuidHelpers";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { computed, defineComponent, onMounted, PropType, ref, watch } from "@vue/composition-api";
import { add, areIntervalsOverlapping, differenceInMinutes, intervalToDuration, parseISO, sub } from "date-fns";
import Vue from "vue";
import EventUpsertMenu from "./EventUpsertMenu.vue";
import { scheduleConflictEventColor, scheduleEventColor, schoolRouteEventColor } from "./schedule.constants";
import {
  EventFormData,
  EventMenuData,
  Interval,
  PlanState,
  ScheduleEntryProps,
  VuetifyCalendarEvent,
} from "./schedule.types";
import ScheduleBanners from "./ScheduleBanners.vue";
import ScheduleCalendar from "./ScheduleCalendar.vue";
import ScheduleList from "./ScheduleList.vue";

export default defineComponent({
  name: "ScheduleForm",
  components: { BaseDatePicker, ScheduleList, ScheduleCalendar, EventUpsertMenu, ScheduleBanners },
  props: {
    minDate: {
      type: String,
      required: true,
    },
    maxDate: {
      type: String,
      required: true,
    },
    schedule: {
      type: Array as PropType<ApiScheduleBaseDto[]>,
      required: false,
    },
    hoursWithInstructor: {
      type: Number,
      required: true,
    },
    hoursMax: {
      type: Number,
      required: false,
    },
    isFormDisplayed: {
      type: Boolean,
      required: false,
      default: true,
    },
    selectedSchoolRouteId: {
      type: Number,
      required: false,
      default: undefined,
    },
    calendarHeight: {
      type: String,
      required: false,
    },
    isCourseDone: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["submit"],
  setup(props, { emit, refs }) {
    const startDate = computed(() => parseISO(props.minDate));
    const endDate = computed(() => parseISO(props.maxDate));
    const schoolRoutes = ref<ApiGetHolidayDto[]>([]);
    const schoolRouteId = ref<number>(props.selectedSchoolRouteId);
    const schoolRoute = computed(() => schoolRoutes.value.find((entry) => entry.id === schoolRouteId.value));
    const isEditing = ref(!!props.schedule?.length);
    const isFormDisabled = ref(isEditing.value);
    const getInitialFormData = (): ScheduleEntryProps => ({
      name: "",
      fromDate: getIsoDate(startDate.value),
      toDate: getIsoDate(endDate.value),
      daysOfWeek: [],
      recurring: 1,
      recurringInterval: "week",
      startTimeOfDay: "",
      endTimeOfDay: "",
    });
    const formData = ref([getInitialFormData()]);
    const addFormDataInterval = () => {
      formData.value.push(getInitialFormData());
      expandedIntervals.value = formData.value.length - 1;
    };
    const removeFormDataInterval = (index: number) => formData.value.splice(index, 1);
    const expandedIntervals = ref(0);
    const scheduleList = ref(
      props.schedule?.length ? mapStoredSchedule(props.schedule) : createScheduleFromList(formData.value)
    );
    const eventMenuData = ref<EventMenuData>({
      visible: false,
    });
    const eventMenuActivator = ref<EventTarget | null>();

    onMounted(async () => {
      if (hasMemberOrgAccess) {
        schoolRoutes.value = [];
        return;
      }
      await globalLoadingWrapper({ blocking: true }, async () => {
        schoolRoutes.value = (await api.holiday.getHolidaysAsync()).data
          .filter((schoolRoute) => schoolRoute.isActive)
          .sort((a, b) => a.name?.localeCompare(b.name ?? "") ?? 0);
      });
    });

    const recreateSchedule = () => {
      const isValid = getValidatableRef(refs.scheduleForm)?.validate();
      if (!schoolRoute.value || !isValid) {
        return;
      }
      scheduleList.value = createScheduleFromList(formData.value);
      isFormDisabled.value = false;
    };

    watch(
      formData,
      async () => {
        await debounceHelper.debounce("scheduleFormDataUpdate", 100);
        recreateSchedule();
      },
      { deep: true }
    );

    const calendarEvents = computed((): VuetifyCalendarEvent[] => {
      if (!scheduleList.value) {
        return [];
      }

      const schoolRouteEvents = getSchoolRouteEvents(schoolRoute.value);

      const eventIsOverlappingIntervals = (event: Interval | VuetifyCalendarEvent, checkIntervals: Interval[]) =>
        checkIntervals.some((interval) => {
          try {
            return areIntervalsOverlapping(event, interval);
          } catch (e) {
            // Ignore interval calculation errors caused by e.g. partially
            // filled form
            return false;
          }
        });

      const scheduleEvents = scheduleList.value
        .filter((event) => event.start && event.end)
        .map(({ id, name, start, end }) => ({
          id,
          name,
          start,
          end,
          color: eventIsOverlappingIntervals({ start, end }, schoolRouteIntervals.value)
            ? scheduleConflictEventColor
            : scheduleEventColor,
          timed: true,
        }));

      return [...scheduleEvents, ...schoolRouteEvents];
    });

    const schoolRouteIntervals = computed(() =>
      (schoolRoute.value?.details ?? []).map(({ fromDate, toDate }) => ({
        start: parseISO(fromDate),
        end: sub(add(parseISO(toDate), { days: 1 }), { seconds: 1 }),
      }))
    );

    const scheduleHours = computed(
      () => +(differenceInMinutes(scheduleInterval.value?.end ?? 0, scheduleInterval.value?.start ?? 0) / 60).toFixed(1)
    );

    const scheduleInterval = computed(() => {
      const scheduleIntervals = scheduleList.value.map(({ start, end }) => ({ start, end }));

      try {
        // Workaround to sum durations: Add durations to a given date, then
        // return the difference between the dates as a duration object
        const start = new Date(0);
        const end = scheduleIntervals.reduce(
          (end, scheduleInterval) => add(end, intervalToDuration(scheduleInterval)),
          start
        );

        return {
          start,
          end,
        };
      } catch {
        return undefined;
      }
    });

    const scheduleConflictInterval = computed(() => {
      const scheduleIntervals = scheduleList.value.map(({ start, end }) => ({ start, end }));

      // Workaround to sum durations: Add durations to a given date, then
      // return the difference between the dates as a duration object
      const start = new Date(0);
      const end = scheduleIntervals.reduce(
        (end, scheduleInterval) =>
          schoolRouteIntervals.value.reduce((end, interval) => {
            try {
              if (!areIntervalsOverlapping(scheduleInterval, interval)) {
                return end;
              }
              return add(end, intervalToDuration(scheduleInterval));
            } catch {
              return end;
            }
          }, end),
        start
      );

      return {
        start,
        end,
      };
    });

    const scheduleConflictList = computed(() =>
      scheduleList.value.reduce<VuetifyCalendarEvent[]>((conflictEntryList, scheduleEntry) => {
        try {
          const { start, end } = scheduleEntry;
          const hasConflict = schoolRouteIntervals.value.some((interval) =>
            areIntervalsOverlapping({ start, end }, interval)
          );
          if (hasConflict) {
            return [...conflictEntryList, scheduleEntry];
          }
        } catch (e) {
          // Ignore interval calculation errors caused by e.g. partially filled form
        }
        return conflictEntryList;
      }, [])
    );

    const scheduleConflictMap = computed(() =>
      scheduleList.value.reduce<Record<string, VuetifyCalendarEvent>>((conflictEntryMap, scheduleEntry) => {
        try {
          const { start, end } = scheduleEntry;
          const hasConflict = schoolRouteIntervals.value.some((interval) =>
            areIntervalsOverlapping({ start, end }, interval)
          );
          if (hasConflict) {
            return { ...conflictEntryMap, [scheduleEntry.id]: scheduleEntry };
          }
        } catch (e) {
          // Ignore interval calculation errors caused by e.g. partially filled form
        }
        return conflictEntryMap;
      }, {})
    );

    const scheduleConflictHours = computed(
      () =>
        +(differenceInMinutes(scheduleConflictInterval.value.end, scheduleConflictInterval.value.start) / 60).toFixed(1)
    );

    const handleClickEvent = ({
      calendarEvent,
      nativeEvent,
    }: {
      calendarEvent?: VuetifyCalendarEvent;
      nativeEvent: MouseEvent;
    }) => {
      if (calendarEvent?.color === schoolRouteEventColor || props.isCourseDone) {
        return;
      }

      const open = () => {
        Vue.nextTick(() => {
          eventMenuData.value.visible = true;
          if (calendarEvent) {
            // Upsert existing event - populate form with event data
            eventMenuData.value.event = {
              id: calendarEvent.id,
              name: calendarEvent.name,
              date: getIsoDate(calendarEvent.start),
              startTime: getTimeOfDay(calendarEvent.start),
              endTime: getTimeOfDay(calendarEvent.end),
            };
          } else {
            // Create new event - populate form with schedule form data
            const [firstItem] = formData.value;
            eventMenuData.value.event = {
              name: firstItem.name,
              date: firstItem.fromDate,
              startTime: firstItem.startTimeOfDay,
              endTime: firstItem.endTimeOfDay,
            };
          }
        });
      };

      eventMenuActivator.value = nativeEvent.target;

      if (eventMenuData.value.visible) {
        eventMenuData.value.visible = false;
      }

      open();

      nativeEvent.stopPropagation();
    };

    const handleUpsertEvent = (event: EventFormData) => {
      if (!event) {
        return;
      }

      const date = parseISO(event.date);
      const start = setDateHourAndMin(date, event.startTime);
      const end = setDateHourAndMin(date, event.endTime);
      const { name, id } = event;

      if (id !== undefined) {
        // Update event
        const event = scheduleList.value.find((schedule) => schedule.id === id);
        if (!event) {
          throw new Error(`Event update failed (event '${id}' not found)`);
        }
        event.name = name;
        event.start = start;
        event.end = end;
      } else {
        // Create new
        scheduleList.value.push({
          id: generateUuid(),
          name,
          start,
          end,
        });
      }

      isFormDisabled.value = true;
    };

    const handleDeleteEvent = (event: EventFormData) => {
      if (!event || event.id === undefined) {
        return;
      }

      scheduleList.value = scheduleList.value.filter((schedule) => schedule.id !== event.id);

      isFormDisabled.value = true;
    };

    const validationState = computed(() => ({
      hasNoConflicts: !scheduleConflictList.value.length,
      hasValidHoursWithInstructor: scheduleHours.value - props.hoursWithInstructor <= 0,
      hasValidHoursMax: props.hoursMax === undefined || scheduleHours.value <= props.hoursMax,
    }));

    const emitState = () => {
      const schedules: ApiScheduleBaseDto[] = scheduleList.value.map(({ name: title, start, end }) => ({
        title,
        start: start.toISOString(),
        end: end.toISOString(),
      }));
      const plan: Partial<ApiUpsertPlanDtoType> = { schoolRouteId: schoolRoute.value?.id ?? 0, schedules };
      const planState: PlanState = { plan, validation: validationState.value };

      emit("updatePlan", planState);
    };

    watch(
      scheduleList,
      async () => {
        emitState();
      },
      { deep: true, immediate: true }
    );

    return {
      startDate,
      endDate,
      allDays,
      validateNotEmpty,
      schoolRoutes,
      schoolRouteId,
      schoolRoute,
      formData,
      addFormDataInterval,
      removeFormDataInterval,
      expandedIntervals,
      eventMenuData,
      calendarEvents,
      getIntervalHoursLabel,
      getRoundedHours,
      scheduleInterval,
      scheduleHours,
      scheduleList,
      scheduleConflictInterval,
      scheduleConflictList,
      scheduleConflictMap,
      scheduleConflictHours,
      recreateSchedule,
      isEditing,
      isFormDisabled,
      handleClickEvent,
      handleUpsertEvent,
      handleDeleteEvent,
      eventMenuActivator,
      validationState,
    };
  },
});
